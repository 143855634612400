import React, { useState } from "react";
import contactus from "../../assets/contactus.png";
import mail from "../../assets/mail.svg";
import { Helmet } from 'react-helmet';


import "./contact.css";
const Contact = () => {
   
 //let us talk form code begin

 const [name, setName] = useState('');
 const [email, setEmail] = useState('');
 const [phone, setPhone] = useState('');
 const [service, setService] = useState('');
 const [message, setMessage] = useState('');
 const [nameError, setNameError] = useState('');
 const [emailError, setEmailError] = useState('');
 const [phoneError, setPhoneError] = useState('');
 const [serviceError, setServiceError] = useState('');
 const [messageError, setMessageError] = useState('');
 const [messageSuccess, setMessageSuccess] = useState('');

 const services = [
  { value: 'branding analysis', label: 'Branding Analysis' },
  { value: 'graphic-designer', label: 'Graphic Designer' },
  { value: 'digital-marketing', label: 'Digital Marketing' },
  { value: 'new business packages', label: 'New Business Packages' },
  { value: 'web development', label: 'Web Development' },
  { value: 'app development', label: 'App Development' },
];

 const handleSelect = (event) => {
   setService(event.target.value);
 };

 const handleSubmit = async (event) => {
   event.preventDefault();

   // Clear previous errors
   setNameError('');
   setEmailError('');
   setPhoneError('');
   setServiceError('');
   setMessageError('');

   // Validation checks
   if (!name.trim()) {
     setNameError('Please enter your name.');
     return;
   }

   if (!email.trim()) {
     setEmailError('Please enter your email.');
     return;
   }

   // Email validation using regular expression
   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
   if (!emailRegex.test(email)) {
     setEmailError('Please enter a valid email address.');
     return;
   }

   if (!phone.trim()) {
     setPhoneError('Please enter your phone number.');
     return;
   }

   // Phone number validation using regular expression
   const phoneRegex = /^[0-9]{10}$/;
   if (!phoneRegex.test(phone)) {
     setPhoneError('Please enter a valid 10-digit phone number.');
     return;
   }

   if (!service) {
     setServiceError('Please select a service.');
     return;
   }

   if (!message.trim()) {
     setMessageError('Please enter your message.');
     return;
   }

   // If all validations pass, proceed with form submission
   const data = { name, email, phone, service, message };
   setMessageSuccess("We will get back to you")
   console.log('Form data:', data);

   // Continue with form submission or API call
   try {
    const response = await fetch('https://www.vasmigroup.com/api/contact-form.php', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
      mode: 'no-cors'
    });

    const result = await response.json();
    console.log('Form submission result:', result);
  } catch (error) {
    console.error('Error submitting form:', error.message);
  }

 // Continue with form submission or API call
 setName('');
 setEmail('');
 setPhone('');
 setService('');
 setMessage('');
 };
//letus talk form code end

  return (
    <div className="contactus-main">
      <Helmet>
        <title>Contact Us | Vasmi Group</title>
        <meta name="description" content="Vasmi Group is available for inquiries, collaborations, and discussing ways to enhance your digital presence and achieve business goals.
" />
      </Helmet>
      <img src={contactus} alt="contact" className="contact-land" />
      <div className="contact-details">
        <p className="contact-address">Address:</p>
        <p className="contact-address-para">#39-14-6/5 <br/>
Madhavadara-Muralinagar Jn.<br/>
Beside ICICI Bank <br/>
Visakhapatnam - 530007</p>
      </div>
      {/* <div className="head-of-company">
        <div className="saikrishna">
          <p className="sainame">SaiKrishna</p>
          <p className="designation">CEO</p>
        </div>
        <div className="niranjan">
          <p className="sainame">Niranjan</p>
          <p className="designation">MD</p>
        </div>
      </div> */}
      <div className="home-form">
        <div className="left-form-sec">
          <p className="home-left-form">SO What Next</p>
          <h1 className="lets-work-form">Let’s Work</h1>
          <p className="not-sure-form">Not Sure Where To start?</p>
          <form method="post" className="form-hom" onSubmit={handleSubmit}>
          <div className="inp-error">

      <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
      {nameError && <p className="error">{nameError}</p>}
          </div>
          <div className="inp-error">

      <input type="text" placeholder="PhoneNumber" value={phone} onChange={(e) => setPhone(e.target.value)} />
      {phoneError && <p className="error">{phoneError}</p>}
      </div>
      <div className="inp-error">

      <input type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
      {emailError && <p className="error">{emailError}</p>}
      </div>
      <div className="inp-error">

      <select name="services" placeholder="Services Interested In" id="services" onChange={handleSelect}>
        <option value="">-- Select Service --</option> {/* Default option */}
        {services.map((serviceOption) => (
          <option key={serviceOption.value} value={serviceOption.value}>
            {serviceOption.label}
          </option>
        ))}
      </select>
      {serviceError && <p className="error">{serviceError}</p>}
      </div>
      <div className="inp-error">

      <textarea name="Message" id="#" cols="30" rows="10" placeholder="Message" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
      {messageError && <p className="error">{messageError}</p>}
      </div>
      <div className="successmeet">

      <button>Submit</button>
      {messageSuccess && <p className="success">{messageSuccess}</p>}
      </div>


    </form>
        </div>
        <div className="right-form-sec">
          <h2 className="left-work-form">Let’s Talk</h2>
          <p className="call-form-home">+91-8142188880</p>
          <p className="call-form-home">+91-7095355556</p>
          <p className="mail-form-home">
            {" "}
            <img src={mail} alt="mail" />Info@vasmigroup.com
          </p>
        </div>
      </div>
      <div className="contact-map">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3800.0024136840234!2d83.25634382463471!3d17.74452544241962!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x29a6e3081c9c8329%3A0x6fa6bf5ab5a112f3!2sVasmi%20Group%20-%20Advertising%20Agency%20in%20Vizag%20%7C%20Best%20Software%20Companies%20in%20Vizag!5e0!3m2!1sen!2sin!4v1721292872797!5m2!1sen!2sin" width="100%" height="100%" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>
  );
};

export default Contact;
