import React, { useState } from "react";
import social from "../../assets/socialtree.png";
import "./smm.css"
import money from "../../assets/moneybag.svg"
import search from "../../assets/search.svg"
import team from "../../assets/team.svg"
import mail from "../../assets/mail.svg";
import socialgrow from "../../assets/socialgrow.png"
import browsing from "../../assets/browsing.svg"
import research from "../../assets/research.svg"
import seo from "../../assets/seo.svg"
import analysis from "../../assets/analysis.svg"
import backlink from "../../assets/backlink.svg"
import tool from "../../assets/tool.svg"
import optimization from "../../assets/optimization.svg"
import product from "../../assets/product.svg"
import mobilemarket from "../../assets/mobilemarket.svg"
import onlinemarket from "../../assets/onlinemarket.svg"
import telegram from "../../assets/telegram.svg"
import capitan from "../../assets/tick.svg"
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import lando from "../../assets/lando.png";
import techlash from "../../assets/techlash.png";
import ex from "../../assets/ex.png";
import js from "../../assets/js.png";
import agv from "../../assets/agv.png";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';


const Smm = () => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate()

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);};
     //let us talk form code begin

 const [name, setName] = useState('');
 const [email, setEmail] = useState('');
 const [phone, setPhone] = useState('');
 const [service, setService] = useState('');
 const [message, setMessage] = useState('');
 const [nameError, setNameError] = useState('');
 const [emailError, setEmailError] = useState('');
 const [phoneError, setPhoneError] = useState('');
 const [serviceError, setServiceError] = useState('');
 const [messageError, setMessageError] = useState('');
 const [messageSuccess, setMessageSuccess] = useState('');

 const services = [
  { value: 'branding analysis', label: 'Branding Analysis' },
  { value: 'graphic-designer', label: 'Graphic Designer' },
  { value: 'digital-marketing', label: 'Digital Marketing' },
  { value: 'new business packages', label: 'New Business Packages' },
  { value: 'web development', label: 'Web Development' },
  { value: 'app development', label: 'App Development' },
];
 const handleSelect = (event) => {
   setService(event.target.value);
 };

 const handleSubmit = async (event) => {
   event.preventDefault();

   // Clear previous errors
   setNameError('');
   setEmailError('');
   setPhoneError('');
   setServiceError('');
   setMessageError('');

   // Validation checks
   if (!name.trim()) {
     setNameError('Please enter your name.');
     return;
   }

   if (!email.trim()) {
     setEmailError('Please enter your email.');
     return;
   }

   // Email validation using regular expression
   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
   if (!emailRegex.test(email)) {
     setEmailError('Please enter a valid email address.');
     return;
   }

   if (!phone.trim()) {
     setPhoneError('Please enter your phone number.');
     return;
   }

   // Phone number validation using regular expression
   const phoneRegex = /^[0-9]{10}$/;
   if (!phoneRegex.test(phone)) {
     setPhoneError('Please enter a valid 10-digit phone number.');
     return;
   }

   if (!service) {
     setServiceError('Please select a service.');
     return;
   }

   if (!message.trim()) {
     setMessageError('Please enter your message.');
     return;
   }

   // If all validations pass, proceed with form submission
   const data = { name, email, phone, service, message };
   setMessageSuccess("We will get back to you")
   console.log('Form data:', data);
    try {
      const response = await fetch('https://www.vasmigroup.com/api/seo-form.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
        mode: 'no-cors'
      });

      const result = await response.json();
      console.log('Form submission result:', result);
    } catch (error) {
      console.error('Error submitting form:', error.message);
    }

   // Continue with form submission or API call
   setName('');
   setEmail('');
   setPhone('');
   setService('');
   setMessage('');
  };
  return (
    <div className="smm-main">
      <Helmet>
        <title>SMM Services company in vizag | Vasmi Group</title>
        <meta name="description" content="Enhance your brand's social media presence with Vasmi Group's SMM services. Engage audiences,drive conversions, and build lasting relationships across platforms" />
      </Helmet>
      <div className="smm-main-land">
        <img src={social} alt="alt" />
        <div className="social-smm-right">
          <p className="right-smm-heading">Enhance Your Presence <strong> <br/>

          SMM by Leading Digital Marketing Agency in Vizag
          </strong> 
          </p>
          <p className="right-smm-para">
          Elevate your online presence with expert Social Media Marketing (SMM) services offered by the best digital marketing agency in Vizag. Our team crafts tailored strategies to maximize your brand's visibility and engagement across various social platforms. With our proven track record and innovative techniques, we drive impactful results that resonate with your target audience. Let us help you amplify your digital footprint and stand out in the competitive online landscape. Unlock your brand's potential today!
          </p>
        </div>
      </div>
      <div className="smm-second-sec">
      <p className="right-smm-heading second-sec-right">Social Media Marketing  <strong><br/>

      
Boost Your Brand's Online Presence Effectively!
</strong> 
</p>
<p className="right-smm-para para-sec-right">
Unlock the power of social media marketing to elevate your brand's online presence. Our tailored strategies are designed to maximize engagement, drive traffic, and foster meaningful connections with your target audience. From compelling content creation to strategic campaign management, we leverage the latest tools and techniques to ensure your brand stands out in the digital landscape. Let us help you amplify your reach, increase brand awareness, and drive measurable results through effective social media marketing strategies.
</p>


      </div>
      <div  className="smm-third-sec">
        <div className="smm-third-sec-left">
        <h1 className="smm-third-sec-left-social">
        Why Social Media Marketing Holds Key 
Importance for Your Business
        </h1>
        <p className="smm-third-sec-left-social-para">
        Discover why social media marketing is imperative for your business's success. As a leading digital marketing agency in Visakhapatnam, we understand the pivotal role of effective social media strategies in enhancing brand visibility and engagement. From increasing brand awareness to driving website traffic and generating leads, our tailored approach ensures your business thrives in the competitive online landscape. Trust us to craft compelling campaigns that resonate with your target audience and deliver tangible results.
        </p>
        <button className="smm-social-btn" onClick={()=>navigate('/contact-us')}>
        Shedule a Call Now
        </button>
        </div>
        <img   src={socialgrow} alt="smmm" />
      </div>
      <div className="smm-fourth-sec">
        <h2 className="right-smm-heading second-sec-right smm-four">
        Discover the Ideal <strong> Social Media Advertising</strong> Platforms for Your Business With Our Assistance
        </h2>
        <p className="smm-fourth-explore">
        Explore tailored social media advertising platforms that best suit your business needs with expert guidance from our digital marketing specialists.
        </p>

        <div className="smm-fourth-media">
          <div className="smm-fifth-sec">
            <img  src={browsing} alt="smmcard"/>
            <p className="smm-fifth-heading">
            FACEBOOK ADVERTISING
            </p>
            <p className="smm-fifth-para">
            Maximize your reach and engagement with targeted Facebook advertising solutions. Our experts craft strategic campaigns to help your business thrive on the world's largest social network
            </p>
          </div>
          <div className="smm-fifth-sec">
            <img  src={research} alt="smmcard"/>
            <p className="smm-fifth-heading">
            TIK TOK ADVERTISING
            </p>
            <p className="smm-fifth-para">
            Elevate your brand's visibility on TikTok with our tailored advertising solutions. Reach a diverse audience and drive engagement with creative campaigns designed by our experts
            </p>
          </div>
          <div className="smm-fifth-sec">
            <img  src={seo} alt="smmcard"/>
            <p className="smm-fifth-heading">
            INSTAGRAM ADVERTISING
            </p>
            <p className="smm-fifth-para">
            Unlock the power of Instagram advertising to reach your target audience effectively. Let our experts create engaging campaigns that drive results and boost your brand presence.
            </p>
          </div>
          <div className="smm-fifth-sec">
            <img  src={analysis} alt="smmcard"/>
            <p className="smm-fifth-heading">
            lINKEDIN ADVERTISING
            </p>
            <p className="smm-fifth-para">
            Reach professionals and decision-makers with LinkedIn advertising. Our expertise as the best digital marketing agency in Vizag ensures impactful campaigns for your business growth.
            </p>
          </div>
          <div className="smm-fifth-sec">
            <img  src={backlink} alt="smmcard"/>
            <p className="smm-fifth-heading">
            SNAPCHAT ADVERTISING
            </p>
            <p className="smm-fifth-para">
            Elevate your brand's presence with Snapchat advertising. Engage a youthful audience and drive conversions with captivating campaigns tailored by our digital marketing experts
            </p>
          </div>
          <div className="smm-fifth-sec">
            <img  src={tool} alt="smmcard"/>
            <p className="smm-fifth-heading">
            PiNTEREST ADVERTISING 
            </p>
            <p className="smm-fifth-para">
            Boost brand visibility with Pinterest advertising. Reach engaged users and drive traffic to your website through visually appealing campaigns crafted by our digital marketing specialists.
            </p>
          </div>
          <div className="smm-fifth-sec">
            <img  src={mobilemarket} alt="smmcard"/>
            <p className="smm-fifth-heading">
            YOuTUBE ADVERTISING 
            </p>
            <p className="smm-fifth-para">
            Drive brand awareness and engagement with YouTube advertising. Let our digital marketing agency in Vizag create impactful video campaigns tailored to your business objectives.
            </p>
          </div>
          <div className="smm-fifth-sec">
            <img  src={optimization} alt="smmcard"/>
            <p className="smm-fifth-heading">
            REDDIT ADVERTISING 
            </p>
            <p className="smm-fifth-para">
        Target niche communities and drive engagement with Reddit advertising. Our digital marketing experts create tailored campaigns to reach your specific audience effectively.
            </p>
          </div>
          <div className="smm-fifth-sec">
            <img  src={optimization} alt="smmcard"/>
            <p className="smm-fifth-heading">
            TWITTER ADVERTISING 
            </p>
            <p className="smm-fifth-para">
            Maximize your brand's reach and engagement with Twitter advertising. Our digital marketing strategies ensure targeted campaigns that drive results and boost brand awareness.
            </p>
          </div>
          <div className="smm-fifth-sec">
            <img  src={onlinemarket} alt="smmcard"/>
            <p className="smm-fifth-heading">
            WHATSAPP ADVERTISING 
            </p>
            <p className="smm-fifth-para">
            Elevate your business with WhatsApp advertising. As the leading digital marketing agency in Visakhapatnam, we craft strategic campaigns to engage your audience effectively.
            </p>
          </div>
          <div className="smm-fifth-sec">
            <img  src={optimization} alt="smmcard"/>
            <p className="smm-fifth-heading">
            QUORA ADVERTISING
            </p>
            <p className="smm-fifth-para">
            Reach a highly engaged audience with Quora advertising. Our digital marketing expertise ensures targeted campaigns that drive traffic and conversions for your business.
            </p>
          </div>
          <div className="smm-fifth-sec">
            <img  src={telegram} alt="smmcard"/>
            <p className="smm-fifth-heading">
            TELEGRAME ADVERTISING
            </p>
            <p className="smm-fifth-para">
            Expand your reach with Telegram advertising. Our digital marketing strategies ensure effective campaigns that engage your audience and drive meaningful results for your business.
            </p>
          </div>

        </div>
      </div>

      <div className="smm-sixth-sec">
        <p className="right-smm-heading second-sec-right">
        Why <strong>
        Vasmi Group
        </strong> Stands Out as Your Paid <strong>Social Media Agency</strong>?
        </p>
        <div className="smm-sixth-crds">

        <div className="smm-sixth-crd">
          <img src={capitan} alt="cool"/>
          <p className="smm-sisth-crd-para">
          Vasmi Group, a top-tier digital marketing agency in Visakhapatnam, offers unparalleled expertise in paid social media marketing strategies and ensures optimal results for clients.
          </p>
        </div>
        <div className="smm-sixth-crd">
          <img src={capitan} alt="cool"/>
          <p className="smm-sisth-crd-para">
          With a focus on innovation and creativity, Vasmi Group distinguishes itself as the best digital marketing agency in Vizag, setting new industry standards.
          </p>
        </div>
        <div className="smm-sixth-crd">
          <img src={capitan} alt="cool"/>
          <p className="smm-sisth-crd-para">
          Our team at Vasmi Group is committed to delivering exceptional results and is backed by years of experience as a leading digital marketing agency in Vizag.
          </p>
        </div>
        <div className="smm-sixth-crd">
          <img src={capitan} alt="cool"/>
          <p className="smm-sisth-crd-para">
          Vasmi Group's comprehensive approach to paid social media marketing encompasses strategic planning, precise targeting, compelling content creation, and meticulous campaign management.
          </p>
        </div>
        <div className="smm-sixth-crd">
          <img src={capitan} alt="cool"/>
          <p className="smm-sisth-crd-para">
          As the premier digital marketing agency in Visakhapatnam, we prioritize client satisfaction, striving to exceed expectations and achieve measurable success in every campaign.
          </p>
        </div>
        <div className="smm-sixth-crd">
          <img src={capitan} alt="cool"/>
          <p className="smm-sisth-crd-para">
          At Vasmi Group, we understand the importance of staying ahead in the digital landscape. Our cutting-edge strategies ensure maximum ROI for your social media marketing efforts.
          </p>
        </div>
        <div className="smm-sixth-crd">
          <img src={capitan} alt="cool"/>
          <p className="smm-sisth-crd-para">
          Partnering with Vasmi Group means gaining access to a team of skilled professionals dedicated to elevating your brand's online presence and driving tangible business results.
          </p>
        </div>
        <div className="smm-sixth-crd">
          <img src={capitan} alt="cool"/>
          <p className="smm-sisth-crd-para">
          Choose Vasmi Group as your trusted digital marketing agency, and let us help you unlock the full potential of your social media marketing endeavors.
          </p>
        </div>
        <div className="smm-sixth-crd">
          <img src={capitan} alt="cool"/>
          <p className="smm-sisth-crd-para">
          Choose Vasmi Group as your trusted digital marketing agency, and let us help you unlock the full potential of your social media marketing endeavors.
          </p>
        </div>
        </div>

      </div>
      <div className='dmv-sec-three'>
      <p className='dmg-sec-two-para'>
      Our Clients

      </p>
      <div className="clients">
        <div className="cli">
          <img src={agv} alt="agv" />
          <img src={lando} alt="agv" />
          <img src={techlash} alt="agv" />
          <img src={js} alt="agv" />
          <img src={ex} alt="agv" />
          <img src={agv} alt="agv" />
          <img src={techlash} alt="agv" />
          <img src={js} alt="agv" />
          <img src={ex} alt="agv" />
        </div>
      </div>
      </div>
      <p className='about-sec-two-head'>
        Frequently Asked Questions
      </p>
      <div className="accord">
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{textAlign:'left', width: "100%", flexShrink: 0, color: "orange" }}>
            What is the difference between SEM and SMM, and which is more suitable for my business?
</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            SEM (Search Engine Marketing) involves paid advertising to increase search engine visibility, while SMM (Social Media Marketing) focuses on engaging audiences through social platforms. The choice depends on your objectives; SEM is ideal for immediate results and targeting specific keywords, while SMM is great for building brand awareness and fostering community engagement.

            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{textAlign:'left', width: "100%", flexShrink: 0, color: "orange" }}>
            Which platforms can I use for SEM and SMM campaigns?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            For SEM campaigns, platforms like Google Ads and Bing Ads are popular choices, offering keyword-based advertising on search engine results pages. SMM campaigns can be executed on social media platforms such as Facebook, Instagram, Twitter, and LinkedIn, each providing unique targeting options and ad formats.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography sx={{ textAlign:'left', flexShrink: 0, color: "orange" }}>
            How do SEM and SMM impact my website's visibility <br/>and traffic?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            SEM drives immediate visibility by displaying ads prominently in search engine results, attracting targeted traffic to your website. SMM builds brand awareness and fosters engagement through social platforms, driving traffic and encouraging interaction with your content.

            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{ textAlign:'left', flexShrink: 0, color: "orange" }}>
            What factors influence the success of SEM and SMM <br/>campaigns?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            Success in SEM relies on factors like keyword selection, ad relevance, bid strategy, and landing page experience. SMM success is influenced by content quality, audience targeting, engagement metrics, and consistency in posting and interaction.

            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel7"}
          onChange={handleChange("panel7")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{textAlign:'left', width: "100%", flexShrink: 0, color: "orange" }}>
            Are SEM and SMM suitable for businesses of all sizes and budgets?

            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            Both SEM and SMM can be tailored to businesses of all sizes and budgets. SEM offers flexibility in bidding and budget control, while SMM provides options for organic engagement and paid advertising with customizable budgets to suit varying needs.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel6"}
          onChange={handleChange("panel6")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography sx={{textAlign:'left', width: "100%", flexShrink: 0, color: "orange" }}>
            How does Vasmi Group approach SEM and SMM to maximize ROI for clients?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            Vasmi Group employs a strategic approach to SEM and SMM, combining in-depth keyword research, compelling ad creative, targeted audience segmentation, and continuous performance monitoring. By optimizing campaigns for maximum ROI, we ensure that clients achieve their objectives effectively within their allocated budgets.

            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="home-form">
        <div className="left-form-sec">
          <p className="home-left-form">SO What Next</p>
          <p className="lets-work-form">Let’s Work</p>
          <p className="not-sure-form">Not Sure Where To start?</p>
          <form method="post" className="form-hom" onSubmit={handleSubmit}>
          <div className="inp-error">

      <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
      {nameError && <p className="error">{nameError}</p>}
          </div>
          <div className="inp-error">

      <input type="text" placeholder="PhoneNumber" value={phone} onChange={(e) => setPhone(e.target.value)} />
      {phoneError && <p className="error">{phoneError}</p>}
      </div>
      <div className="inp-error">

      <input type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
      {emailError && <p className="error">{emailError}</p>}
      </div>
      <div className="inp-error">

      <select name="services" placeholder="Services Interested In" id="services" onChange={handleSelect}>
        <option value="">-- Select Service --</option> {/* Default option */}
        {services.map((serviceOption) => (
          <option key={serviceOption.value} value={serviceOption.value}>
            {serviceOption.label}
          </option>
        ))}
      </select>
      {serviceError && <p className="error">{serviceError}</p>}
      </div>
      <div className="inp-error">

      <textarea name="Message" id="#" cols="30" rows="10" placeholder="Message" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
      {messageError && <p className="error">{messageError}</p>}
      </div>
      <div className="successmeet">

      <button>Submit</button>
      {messageSuccess && <p className="success">{messageSuccess}</p>}
      </div>


    </form>
        </div>
        <div className="right-form-sec">
          <p className="left-work-form">Let’s Talk</p>
          <p className="call-form-home">+91-8142188880</p>
          <p className="call-form-home">+91-7095355556</p>
          <p className="mail-form-home">
            {" "}
            <img src={mail} alt="mail" />Info@vasmigroup.com
          </p>
        </div>
      </div>
    
    </div>
  );
};

export default Smm;
