import React, { useState } from 'react';
import "./software.css"

import landvideo from "../../assets/sftdev.mp4"
import softwareone from "../../assets/softwareone.png"
import latestproduct from "../../assets/latestproduct.png"
import aadyaweb from "../../assets/aadyaweb.png"
import next from "../../assets/next.png"
import javascript from "../../assets/javascript.svg"
import react from "../../assets/react.svg"
import css from "../../assets/css.svg"
import html from "../../assets/html.svg"
import torch from "../../assets/torch.png"
import appdev from "../../assets/appdev.png"
import dashboard from "../../assets/dashboard.png"
import wordpressdev from "../../assets/wordpressweb.png"
import partners from "../../assets/partners.png"
import gallery2 from "../../assets/arrows.png";
import gallery3 from "../../assets/aadhyadesign.png";
import gallery4 from "../../assets/techlashdesign.png";
import gallery5 from "../../assets/copper.png";
import gallery6 from "../../assets/appdesign.png";
import gallery1 from "../../assets/chart.png";
import blogpost from "../../assets/blogpost.png";
import creative from "../../assets/creative.png";
import mail from "../../assets/mail.svg";
import lando from "../../assets/lando.png";
import techlash from "../../assets/techlash.png";
import ex from "../../assets/ex.png";
import js from "../../assets/js.png";
import seo from "../../assets/seo.svg"
import agv from "../../assets/agv.png";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from 'react-router-dom';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { Helmet } from 'react-helmet';


const SoftwareDevelopment = () => {


  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate()

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

 //let us talk form code begin

 const [name, setName] = useState('');
 const [email, setEmail] = useState('');
 const [phone, setPhone] = useState('');
 const [service, setService] = useState('');
 const [message, setMessage] = useState('');
 const [nameError, setNameError] = useState('');
 const [emailError, setEmailError] = useState('');
 const [phoneError, setPhoneError] = useState('');
 const [serviceError, setServiceError] = useState('');
 const [messageError, setMessageError] = useState('');
 const [messageSuccess, setMessageSuccess] = useState('');

 const services = [
  { value: 'branding analysis', label: 'Branding Analysis' },
  { value: 'graphic-designer', label: 'Graphic Designer' },
  { value: 'digital-marketing', label: 'Digital Marketing' },
  { value: 'new business packages', label: 'New Business Packages' },
  { value: 'web development', label: 'Web Development' },
  { value: 'app development', label: 'App Development' },
];

 const handleSelect = (event) => {
   setService(event.target.value);
 };

 const handleSubmit = async (event) => {
   event.preventDefault();

   // Clear previous errors
   setNameError('');
   setEmailError('');
   setPhoneError('');
   setServiceError('');
   setMessageError('');

   // Validation checks
   if (!name.trim()) {
     setNameError('Please enter your name.');
     return;
   }

   if (!email.trim()) {
     setEmailError('Please enter your email.');
     return;
   }

   // Email validation using regular expression
   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
   if (!emailRegex.test(email)) {
     setEmailError('Please enter a valid email address.');
     return;
   }

   if (!phone.trim()) {
     setPhoneError('Please enter your phone number.');
     return;
   }

   // Phone number validation using regular expression
   const phoneRegex = /^[0-9]{10}$/;
   if (!phoneRegex.test(phone)) {
     setPhoneError('Please enter a valid 10-digit phone number.');
     return;
   }

   if (!service) {
     setServiceError('Please select a service.');
     return;
   }

   if (!message.trim()) {
     setMessageError('Please enter your message.');
     return;
   }

   // If all validations pass, proceed with form submission
   const data = { name, email, phone, service, message };
   setMessageSuccess("We will get back to you")
   console.log('Form data:', data);

   // Continue with form submission or API call
   try {
    const response = await fetch('https://www.vasmigroup.com/api/software-form.php', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
      mode: 'no-cors'
    });

    const result = await response.json();
    console.log('Form submission result:', result);
  } catch (error) {
    console.error('Error submitting form:', error.message);
  }

 // Continue with form submission or API call
 setName('');
 setEmail('');
 setPhone('');
 setService('');
 setMessage('');
};
//letus talk form code end



  return (
    <div className='sde'>
      <Helmet>
        <title>Web Desigining company in vizag | Vasmi Group </title>
        <meta name="description" content="Vasmi Group offers innovative web design solutions to enhance your online presence, enhancing user experience and driving business success." />
      </Helmet>
      <div className='sde-one'>
      <video className="landvideo" autoPlay loop muted>
        <source src={landvideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="waviy">
  <span style={{'--i': 1}}>S</span>
  <span style={{'--i': 2}}>o</span>
  <span style={{'--i': 3}}>f</span>
  <span style={{'--i': 4}}>t</span>
  <span style={{'--i': 5}}>w</span>
  <span style={{'--i': 6}}>a</span>
  <span style={{'--i': 7}}>r</span>
  <span style={{'--i': 8}}>e</span>
  <span style={{'--i': 9}}> </span>
  <span style={{'--i': 10}} className="d i">D</span>
  <span style={{'--i': 11}} className="d">e</span>
  <span style={{'--i': 12}} className="d">v</span>
  <span style={{'--i': 13}} className="d">e</span>
  <span style={{'--i': 14}} className="d">l</span>
  <span style={{'--i': 15}} className="d">o</span>
  <span style={{'--i': 16}} className="d">p</span>
  <span style={{'--i': 17}} className="d">m</span>
  <span style={{'--i': 18}} className="d">e</span>
  <span style={{'--i': 19}} className="d">n</span>
  <span style={{'--i': 20}} className="d">t</span>
</div>


      </div>
      <div className='sde-sec-one'>
        <div className='sde-sec-one-left'>
            <p className='sde-sec-left-heading'>
            Innovative Software Solutions:<br/>
            <strong>

Website Design in Vizag
            </strong>

            </p>
            <p className='sde-sec-one-para'>
            Unlock the potential of your business with our comprehensive software development services. From e-commerce platforms to bespoke web and app development, we craft solutions tailored to your needs. Our expertise extends to dashboard development, ensuring intuitive data management. Elevate your online presence with our WordPress development services, offering scalable and customizable solutions. Partner with us to harness cuttingedge technology and propel your business forward in the vibrant digital landscape of Vizag.

            </p>

            <button className='sde-sec-one-btn' onClick={()=>navigate('/recruitment')}>
            Get Started

            </button> 
        </div>
        <img src={softwareone} alt='sde'/>
      </div>
            <h1 className='sde-sectwo-tools'>
                Our Tools
            </h1>
      <div className='sde-tools'>
        <img src={javascript} alt='tools' className='sdetools-one'/>
        <img src={html} alt='tools' className='sdetools-one'/>
        <img src={css} alt='tools' className='sdetools-one'/>
        <img src={react} alt='tools' className='sdetools-one'/>
        <img src={next} alt='tools' className='sdetools-one'/>
      </div>


      <div className='sde-developments-tools'>
      <h2 className='sde-sectwo-tools'>Type of Developments we do</h2>
      <div className='sde-sec-one sde-sec-one-five'>
        <div className='sde-sec-one-left'>
            <p className='sde-sec-left-heading'>
            E-commerce Solutions from  <strong>

             Web Designing Companies in Vizag
            </strong>

            </p>
            <p className='sde-sec-one-para'>
            Empower your online business with our expert e-commerce website development services. Our team combines creativity and functionality to create captivating online stores that drive sales and engagement. As one of Vizag's top web designing companies, we specialize in delivering seamless user experiences tailored to your target audience. Trust us to transform your vision into a visually stunning and user-friendly e-commerce platform that stands out in the competitive landscape of web designing in Vizag.

            </p>

            <button className='sde-sec-two-btn' onClick={()=>navigate('/contact-us')}>
            Contact us

            </button> 
        </div>
        <img src={latestproduct} alt='sde'/>
      </div>

      <div className='sde-sec-one'>
      <img src={aadyaweb} alt='sde'/>

        <div className='sde-sec-one-left'>
            <p className='sde-sec-left-heading'>
            Expert  <strong>

            Web Development
            </strong> For Website Design in Vizag

            </p>
            <p className='sde-sec-one-para'>
            Experience top-tier web development services tailored to your needs with our Vizag-based team. From dynamic websites to intricate web applications, we blend creativity and functionality to craft digital solutions that leave a lasting impression. Our expertise in website design in Vizag ensures visually appealing interfaces that captivate your audience. Trust us to bring your vision to life and establish a strong online presence that sets you apart from the competition. Let's embark on a journey to digital success together.

            </p>

            <button className='sde-sec-two-btn' onClick={()=>navigate('/contact-us')}>
            Contact us

            </button> 
        </div>
      </div>

      <div className='sde-sec-one sde-sec-one-five'>
        <div className='sde-sec-one-left'>
            <p className='sde-sec-left-heading'>
            Innovative Services <strong>

            App Development            </strong>
            Services
            </p>
            <p className='sde-sec-one-para'>
            Elevate your digital presence with our cutting-edge app development services. Whether it's iOS, Android, or cross-platform solutions, our Vizag-based team delivers intuitive and engaging apps tailored to your business goals. We prioritize user experience and functionality, ensuring seamless performance across devices. As experts in web designing in Vizag, we integrate sleek designs with robust functionality to create apps that stand out in the competitive market. Partner with us to turn your app idea into a reality and make an impact in the digital realm.

            </p>

            <button className='sde-sec-two-btn' onClick={()=>navigate('/contact-us')}>
            Contact us

            </button> 
        </div>
        <img src={appdev} alt='sde'/>
      </div>

      <div className='sde-sec-one'>
      <img src={dashboard} alt='sde'/>

        <div className='sde-sec-one-left'>
            <p className='sde-sec-left-heading'>
            Dynamic  <strong>

            Dashboard Development
            </strong> For Web Designing in Vizag

            </p>
            <p className='sde-sec-one-para'>
            Empower your business with our dynamic dashboard development services. From data visualization to analytics integration, our team creates intuitive dashboards that provide actionable insights. As one of the leading web designing companies in Vizag, we prioritize sleek design and seamless functionality to ensure an exceptional user experience. Whether you need a dashboard for business intelligence, project management, or analytics tracking, we tailor our solutions to meet your specific needs. Partner with us to enhance decisionmaking and drive business growth.

            </p>

            <button className='sde-sec-two-btn' onClick={()=>navigate('/contact-us')}>
            Contact us

            </button> 
        </div>
      </div>
      <div className='sde-sec-one sde-sec-one-five'>
        <div className='sde-sec-one-left'>
            <p className='sde-sec-left-heading'>
            Custom WordPress Solutions: <strong>
            
             Web Designing Companies in Vizag
            </strong>
            </p>
            <p className='sde-sec-one-para'>
            Transform your online presence with our specialized WordPress development services. Based in Vizag, our team crafts visually stunning and user-friendly websites that resonate with your audience. Elevate your brand with customized website design in Vizag, tailored to your unique business needs. Partner with us to unlock the full potential of your digital platform and stand out in the competitive landscape of web designing in companies in Vizag.

            </p>

            <button className='sde-sec-two-btn' onClick={()=>navigate('/contact-us')}>
            Contact us

            </button> 
        </div>
        <img src={wordpressdev} alt='sde'/>
      </div>
      </div>
      <img src={partners} alt='partners' className='partners'/>

      <div className="recruit-gallery">
        <p className="platform-heading-r">Our Company Gallery</p>

        <div className="gallery-r">
          <div className="gallery-r-one">
            <img src={gallery1} alt="gallery" />
            <img src={gallery2} alt="gallery" />
          </div>
          <img src={gallery3} alt="gallery" />
          <div className="gallery-r-one">
            <img src={gallery4} alt="gallery" />
            <img src={gallery5} alt="gallery" />
          </div>
          <img src={gallery6} alt="gallery" className='galleryout' />
        </div>
      </div>
      
      <div className='dmv-sec-three'>
      <p className='dmg-sec-two-para'>
      Our Clients

      </p>
      <div className="clients">
        <div className="cli">
          <img src={agv} alt="agv" />
          <img src={lando} alt="agv" />
          <img src={techlash} alt="agv" />
          <img src={js} alt="agv" />
          <img src={ex} alt="agv" />
          <img src={agv} alt="agv" />
          <img src={techlash} alt="agv" />
          <img src={js} alt="agv" />
          <img src={ex} alt="agv" />
        </div>
      </div>


      </div>
      <p className='about-sec-two-head'>
        Frequently Asked Questions
      </p>
      <div className="accord">
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{textAlign:'left', width: "100%", flexShrink: 0, color: "orange" }}>
            What role does web design play in shaping a brand's online presence?

            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            Web design is instrumental in creating a visually appealing and user-friendly online platform that reflects the brand's identity and values, effectively communicating its message to the target audience.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{textAlign:'left', width: "100%", flexShrink: 0, color: "orange" }}>
            How can a well-designed website enhance user experience and engagement?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            A well-designed website prioritizes ease of navigation, intuitive layout, and visually appealing elements, leading to improved user experience and increased engagement with the content and offerings.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography sx={{ textAlign:'left', flexShrink: 0, color: "orange" }}>
            What factors should be considered when designing a website <br/>for mobile responsiveness?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            Factors such as responsive layout, flexible images and media, touch-friendly navigation, and fast loading times are essential for ensuring optimal mobile responsiveness and usability across various devices.

            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{ textAlign:'left', flexShrink: 0, color: "orange" }}>
            How does website design impact search engine optimization <br/>(SEO) performance?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            Website design elements such as site structure, page load speed, mobile optimization, and use of relevant keywords directly influence SEO performance, affecting the website's visibility and ranking on search engine results pages (SERPs).

            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel7"}
          onChange={handleChange("panel7")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{textAlign:'left', width: "100%", flexShrink: 0, color: "orange" }}>
            What are the latest trends in web design that businesses should be aware of?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            Some of the latest trends in web design include minimalist design, bold typography, dark mode, micro-interactions, 3D elements, and immersive storytelling, which businesses can leverage to stay current and engage their audience effectively.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel6"}
          onChange={handleChange("panel6")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography sx={{textAlign:'left', width: "100%", flexShrink: 0, color: "orange" }}>
            Why is it important for businesses to invest in professional web design services?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            Investing in professional web design services ensures that businesses have a visually appealing, user-friendly, and technically optimized website that aligns with their brand identity, enhances credibility, and drives conversions and growth in the competitive online landscape.

            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="home-form">
        <div className="left-form-sec">
          <p className="home-left-form">SO What Next</p>
          <p className="lets-work-form">Let’s Work</p>
          <p className="not-sure-form">Not Sure Where To start?</p>
          <form method="post" className="form-hom" onSubmit={handleSubmit}>
          <div className="inp-error">

      <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
      {nameError && <p className="error">{nameError}</p>}
          </div>
          <div className="inp-error">

      <input type="text" placeholder="PhoneNumber" value={phone} onChange={(e) => setPhone(e.target.value)} />
      {phoneError && <p className="error">{phoneError}</p>}
      </div>
      <div className="inp-error">

      <input type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
      {emailError && <p className="error">{emailError}</p>}
      </div>
      <div className="inp-error">

      <select name="services" placeholder="Services Interested In" id="services" onChange={handleSelect}>
        <option value="">-- Select Service --</option> {/* Default option */}
        {services.map((serviceOption) => (
          <option key={serviceOption.value} value={serviceOption.value}>
            {serviceOption.label}
          </option>
        ))}
      </select>
      {serviceError && <p className="error">{serviceError}</p>}
      </div>
      <div className="inp-error">

      <textarea name="Message" id="#" cols="30" rows="10" placeholder="Message" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
      {messageError && <p className="error">{messageError}</p>}
      </div>
      <div className="successmeet">

      <button>Submit</button>
      {messageSuccess && <p className="success">{messageSuccess}</p>}
      </div>


    </form>
        </div>
        <div className="right-form-sec">
          <p className="left-work-form">Let’s Talk</p>
          <p className="call-form-home">+91-8142188880</p>
          <p className="call-form-home">+91-7095355556</p>
          <p className="mail-form-home">
            {" "}
            <img src={mail} alt="mail" />Info@vasmigroup.com
          </p>
        </div>
      </div>
    </div>
  );
}

export default SoftwareDevelopment;
