import React,{ useState, useEffect } from 'react';
import { useParams,Link } from 'react-router-dom';
import arrowpng from '../../assets/arrowpng.svg';

import "./blogs.css"
import { Helmet } from 'react-helmet';
const MAX_TITLE_LENGTH = 20;
const MAX_EXCERPT_LENGTH = 150;

const Blogs = () => {
    const { postid } = useParams();
    const [post, setPost] = useState(null);
  const [error, setError] = useState(null);
  const [categoryPosts, setCategoryPosts] = useState([]); // State for recent posts data
  const[relatedPosts,setRelatedPosts] = useState([]); // State for related posts data

  const truncateTitle = (title) => {
    if (title.length > MAX_TITLE_LENGTH) {
      return title.substring(0, MAX_TITLE_LENGTH);
    }
    return title;
  };

  const truncateExcerpt = (excerpt) => {
    if (excerpt.length > MAX_EXCERPT_LENGTH) {
      return excerpt.substring(0, MAX_EXCERPT_LENGTH) + '...';
    }
    return excerpt;
  };

  const myStyle = { width: '334px', height: '238px' };

  useEffect(() => {
    const fetchData = async () => {
      // const id = window.location.search.slice(1).split('&')[0].split('=')[1]; // Extract ID from URL
      const id = window.location.pathname.slice(1).split('&')[0].split('=')[1]; // Extract ID from URL
      try {
        const response = await fetch(`https://www.vasmigroup.com/api/blogpost.php?postid=${id}`); // Fetch data from the API
        const data = await response.json();
        setPost(data);
        // Fetch recent blog posts (assuming separate API endpoint)
        const categoryPosts = await fetch(`https://www.vasmigroup.com/api/category_page.php`);
        const categoryPostsData = await categoryPosts.json();
        setCategoryPosts(categoryPostsData); // Limit to 3 recent posts

        // Fetch recent blog posts (assuming separate API endpoint)
        const relatedResponse = await fetch(`https://www.vasmigroup.com/api/related_posts.php?postid=${id}`);
        const relatedData = await relatedResponse.json();
        setRelatedPosts(relatedData); // Limit to 3 recent posts

      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, [postid]);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0'); // Pad with leading zero
    const month = date.toLocaleString('default', { month: 'short' }); // Use short month name
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };
  if (post) {
  return (
    <div className='blog-main-ind'>
      <Helmet>
        <title>Blogs | Vasmi Group</title>
        <meta name="description" content="Vasmi Group's blog provides valuable insights, trends, and expert tips on digital marketing, web design, and other business success strategies." />
      </Helmet>
      <div className='blog-individual'>
      {post.blogimage && (
  <img src={`https://www.vasmigroup.com/admin/${post.blogimage}`} alt={post.title} />
)}
      
      </div>
      <h1 className="platform-heading-r">{post.title}</h1>

      <div className='blog-sec-main'>
      <div className='blog-sec-left-main'>
        <p className='blog-sec-main-para' dangerouslySetInnerHTML={{ __html: post.content }} />

        <div className='blogs-container'>
      {relatedPosts.map((relatedPost) => (
<div className='blogs-one-vasmi'>
 <img src={`https://www.vasmigroup.com/admin/${relatedPost.blogimage}`}  alt="blog" style={myStyle}  />
  <p className='marketing-blogs'>{truncateTitle(relatedPost.title)}</p>
  <p className='marketing-blogs-para'>{truncateExcerpt(relatedPost.excerpt)}</p>
  <Link to={"/blogs/postid="+relatedPost.id} className='blogs-read-btn'>Read More<img src={arrowpng}  alt='arrow'/></Link>

</div>
))}
</div>

      </div>
      <div className='blog-sec-right-main'>
        <p className='blog-sec-right-para' >{formatDate(post.created_at)}</p>

        <div className='blogs-items-ind'>
        <h2 className='blogs-recents'>Recent Blogs</h2>
                  {categoryPosts.map((categoryPost) => (
                    <p className='item-blog' key={categoryPost.category_id}>
                      <a href={`/category/catname=${categoryPost.name}`}>{truncateTitle(categoryPost.name)}</a>
                    </p>
                  ))}
        </div>


      </div>


      </div>


    </div>
  );
}else {
  return <div>Loading...</div>; // Or display an error message
}
};

export default Blogs;
