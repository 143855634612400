import React, { useState } from 'react';
import "./seo.css"
import browsing from "../../assets/browsing.svg"
import research from "../../assets/research.svg"
import seo from "../../assets/seo.svg"
import analysis from "../../assets/analysis.svg"
import backlink from "../../assets/backlink.svg"
import tool from "../../assets/tool.svg"
import optimization from "../../assets/optimization.svg"
import product from "../../assets/product.svg"
import marketing from "../../assets/marketing.svg"
import money from "../../assets/moneybag.svg"
import search from "../../assets/search.svg"
import team from "../../assets/team.svg"
import monitor from "../../assets/monitor.svg"
import forecast from "../../assets/forecast.svg"
import progressw from "../../assets/workprogress.svg"
import lando from "../../assets/lando.png";
import techlash from "../../assets/techlash.png";
import ex from "../../assets/ex.png";
import js from "../../assets/js.png";
import seoheader from "../../assets/seoheader.png"
import agv from "../../assets/agv.png";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import mail from "../../assets/mail.svg";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';


const Seo = () => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
  const navigate = useNavigate()

  
    //let us talk form code begin

 const [name, setName] = useState('');
 const [email, setEmail] = useState('');
 const [phone, setPhone] = useState('');
 const [service, setService] = useState('');
 const [message, setMessage] = useState('');
 const [nameError, setNameError] = useState('');
 const [emailError, setEmailError] = useState('');
 const [phoneError, setPhoneError] = useState('');
 const [serviceError, setServiceError] = useState('');
 const [messageError, setMessageError] = useState('');
 const [messageSuccess, setMessageSuccess] = useState('');

 const services = [
  { value: 'branding analysis', label: 'Branding Analysis' },
  { value: 'graphic-designer', label: 'Graphic Designer' },
  { value: 'digital-marketing', label: 'Digital Marketing' },
  { value: 'new business packages', label: 'New Business Packages' },
  { value: 'web development', label: 'Web Development' },
  { value: 'app development', label: 'App Development' },
];
 const handleSelect = (event) => {
   setService(event.target.value);
 };

 const handleSubmit = async (event) => {
   event.preventDefault();

   // Clear previous errors
   setNameError('');
   setEmailError('');
   setPhoneError('');
   setServiceError('');
   setMessageError('');

   // Validation checks
   if (!name.trim()) {
     setNameError('Please enter your name.');
     return;
   }

   if (!email.trim()) {
     setEmailError('Please enter your email.');
     return;
   }

   // Email validation using regular expression
   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
   if (!emailRegex.test(email)) {
     setEmailError('Please enter a valid email address.');
     return;
   }

   if (!phone.trim()) {
     setPhoneError('Please enter your phone number.');
     return;
   }

   // Phone number validation using regular expression
   const phoneRegex = /^[0-9]{10}$/;
   if (!phoneRegex.test(phone)) {
     setPhoneError('Please enter a valid 10-digit phone number.');
     return;
   }

   if (!service) {
     setServiceError('Please select a service.');
     return;
   }

   if (!message.trim()) {
     setMessageError('Please enter your message.');
     return;
   }

   // If all validations pass, proceed with form submission
   const data = { name, email, phone, service, message };
   setMessageSuccess("We will get back to you")
   console.log('Form data:', data);
    try {
      const response = await fetch('https://www.vasmigroup.com/api/seo-form.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
        mode: 'no-cors'
      });

      const result = await response.json();
      console.log('Form submission result:', result);
    } catch (error) {
      console.error('Error submitting form:', error.message);
    }

   // Continue with form submission or API call
   setName('');
   setEmail('');
   setPhone('');
   setService('');
   setMessage('');
  };

  return (
    <div className='seo-main'>
      <Helmet>
        <title>SEO Services company in vizag | Vasmi Group</title>
        <meta name="description" content="Vasmi Group offers SEO services to enhance your website's potential, boosting visibility, driving traffic, and achieving higher rankings through proven strategies." />
      </Helmet>

    <img src={seoheader} alt='seo' className='img-seo'/>
    <div className='seo-land'>
        <h1 className='seo-sec-head'>
        Top SEO Company Vizag 
        </h1>

        <p className='para-seo-sec'>

 Elevate Your Online Presence with 
Expert Digital Marketing Services
        </p>
        <p className='para-sec-sub'>
        Unlock unparalleled online visibility with our SEO services in Vizag, designed to propel your digital success. At Vasmi Group, we specialize in maximizing your website's reach through strategic optimization techniques. Our dedicated team ensures that your brand stands out in search engine results, driving organic traffic and boosting conversions. With a focus on the latest SEO trends and algorithms, we craft a tailored approach that aligns with your business goals. Elevate your online presence with the transformative power of effective SEO.

        </p>
        <div className='seo-sec-btns'>
            <button className='seo-sec-btn' onClick={()=>navigate('/contact-us')}>
            Get Free SEO Audit
            </button>
            {/* <button className='seo-sec-chat'>
            Chat On Whatsapp
            </button> */}
        </div>

    </div>
    <div className='seo-sec-three'>
        <div className='seo-sec-box'>
            <h2 className='seo-sec-para'>
            Top SEO Company  
Vizag
            </h2>
            <p className='seo-sec-line'>

            </p>
            <p className='seo-sec-para-subb'>
            Elevate your online presence with our PPC services, strategically placing your brand at the forefront. We go beyond the services we offer for your visibility.

            </p>
        </div>
    </div>

    <div className='seo-four-sec'>
        <div className='seo-four-card'>
            <img src={browsing} alt='seo'/>
            <p className='seo-four-card-head'>Key Word Research</p>
            <p className='seo-four-card-sub-para'>Optimize your online presence with meticulous keyword research. Elevate your rankings and reach your target audience effectively, ensuring superior visibility and engagement.
            
            </p>
        </div>
        <div className='seo-four-card'>
            <img src={research} alt='seo'/>
            <p className='seo-four-card-head'>Competitor Research</p>
            <p className='seo-four-card-sub-para'>
            Enhance your strategy with thorough competitor research. Our SEO services in Visakhapatnam analyze competitors, ensuring your digital presence outshines rivals for sustained success and visibility.

            </p>
        </div>
        <div className='seo-four-card'>
            <img src={seo} alt='seo'/>
            <p className='seo-four-card-head'>Website Audit</p>
            <p className='seo-four-card-sub-para'>
            Revitalize your online presence with our comprehensive website audit and optimization services. Enhance performance, user experience, and search engine rankings for sustained digital success.

            </p>
        </div>
        <div className='seo-four-card'>
            <img src={analysis} alt='seo'/>
            <p className='seo-four-card-head'>Technical SEO</p>
            <p className='seo-four-card-sub-para'>
            Elevate your online performance with our specialized technical SEO in Vizag. We optimize your website's infrastructure for superior search engine visibility and sustained digital success.

            </p>
        </div>
        <div className='seo-four-card'>
            <img src={backlink} alt='seo'/>
            <p className='seo-four-card-head'>On-Page SEO</p>
            <p className='seo-four-card-sub-para'>
            Transform your online visibility with our ON-Page SEO services. As the top SEO company Vizag, we optimize content for higher rankings and sustained digital prominence.

            </p>
        </div>
        <div className='seo-four-card'>
            <img src={tool} alt='seo'/>
            <p className='seo-four-card-head'>LINk Building</p>
            <p className='seo-four-card-sub-para'>
            Elevate your website's visibility with expert link-building services. Enhance SEO in Vizag and boost rankings for targeted keywords. Contact us today!
            </p>
        </div>
        <div className='seo-four-card'>
            <img src={optimization} alt='seo'/>
            <p className='seo-four-card-head'>Content  Optimization</p>
            <p className='seo-four-card-sub-para'>
            Maximize your online presence with strategic content optimization. Enhance SEO rankings and effectively engage your audience. Elevate your digital marketing strategy today!
            </p>
        </div>
        <div className='seo-four-card'>
            <img src={optimization} alt='seo'/>
            <p className='seo-four-card-head'>Rank Tracking Progress</p>
            <p className='seo-four-card-sub-para'>
            Stay ahead of the competition with our comprehensive rank-tracking progress. Monitor SEO performance and optimize strategies effectively. Elevate your online presence with SEO services in Visakhapatnam.

            </p>
        </div>
        <div className='seo-four-card'>
            <img src={marketing} alt='seo'/>
            <p className='seo-four-card-head'>Analysis & Reporting</p>
            <p className='seo-four-card-sub-para'>
            Gain insights and track progress with our thorough analysis and reporting. Monitor SEO performance and make data-driven decisions to enhance your online presence
            </p>
        </div>
    </div>

    <div className="smm-second-sec">
      <p className="right-smm-heading second-sec-right " id='seo-second-sec-right'>“Unlock Success with the Expertise of  <strong>

      
      Top SEO Company Vizag”
</strong> 
</p>


<div className="smm-crd-sec">
  <div className="smm-crd-one">
    <img src={money} alt="smm"/>
    <div className="smm-crd-one-right">
      <p className="smm-crd-right-para">We have generated over</p>
      <p className="smm-crd-right-para">₹ 2,222,145,500</p>
      <p className="smm-crd-right-para">in paid revenue</p>
    </div>
  </div>
  <div className="smm-crd-one">
    <img src={search} alt="smm"/>
    <div className="smm-crd-one-right">
      <p className="smm-crd-right-para">We have generated</p>
      <p className="smm-crd-right-para">5000+</p>
      <p className="smm-crd-right-para">organic leads in SEO</p>
    </div>
  </div>
  <div className="smm-crd-one">
    <img src={team} alt="smm"/>
    <div className="smm-crd-one-right">
      <p className="smm-crd-right-para">We have dedicated team</p>
      <p className="smm-crd-right-para">40+ </p>
      <p className="smm-crd-right-para">SEO Experts</p>
    </div>
  </div>
</div>

<p className="right-smm-heading second-sec-right">Unveiling Additional Features of Our Exclusive <strong>

      
SEO Services
</strong> 
</p>
<p>
Increase  your  search rankings and get discovered more online
</p>

<div className='ranking-secion-one'>
    <div className='ranking-sec-card'>
        <img src={forecast} alt='seo'/>
        <p className='ranking-sec-head'>Regular Reporting </p>
        <p className='ranking-sec-para'>Stay informed and track progress with our regular reporting services. Monitor SEO performance and make data-driven decisions to optimize your digital marketing strategy effectively.
        
        </p>
    </div>
    <div className='ranking-sec-card'>
        <img src={monitor} alt='seo'/>
        <p className='ranking-sec-head'>Website Monitoring </p>
        <p className='ranking-sec-para'>Ensure peak performance with our website monitoring services. Stay ahead of the curve with realtime insights from the top SEO company Vizag.

        </p>
    </div>
    <div className='ranking-sec-card'>
        <img src={progressw} alt='seo'/>
        <p className='ranking-sec-head'>COmpetitor Analysys  </p>
        <p className='ranking-sec-para'>Gain a competitive edge with our comprehensive competitor analysis services. Enhance your SEO strategy and dominate the market with SEO services in Vizag.

        </p>
    </div>
</div>
      </div>
      <div className='dmv-sec-three'>
      <p className='dmg-sec-two-para'>
      Our Clients

      </p>
      <div className="clients">
        <div className="cli">
          <img src={agv} alt="agv" />
          <img src={lando} alt="agv" />
          <img src={techlash} alt="agv" />
          <img src={js} alt="agv" />
          <img src={ex} alt="agv" />
          <img src={agv} alt="agv" />
          <img src={techlash} alt="agv" />
          <img src={js} alt="agv" />
          <img src={ex} alt="agv" />
        </div>
      </div>
      </div>
      <p className='about-sec-two-head'>
        Frequently Asked Questions
      </p>
      <div className="accord">
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{textAlign:'left', width: "100%", flexShrink: 0, color: "orange" }}>
            What is SEO and why is it important for my website?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            SEO, or Search Engine Optimization, is crucial for increasing visibility and organic traffic to your website by improving its ranking on search engine results pages (SERPs), ultimately enhancing brand exposure and driving conversions.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{textAlign:'left', width: "100%", flexShrink: 0, color: "orange" }}>
            How long does it take to see results from SEO efforts?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            The timeline for SEO results varies depending on factors such as website age, competition, and the effectiveness of optimization strategies, but typically, noticeable improvements can be seen within a few months, with more significant results over time.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography sx={{ textAlign:'left', flexShrink: 0, color: "orange" }}>
            What are the key components of a successful SEO strategy?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            A successful SEO strategy involves on-page optimization (such as keyword research, content creation, and meta tags), off-page optimization (including link building and social signals), technical optimization (site structure and performance), and continuous monitoring and refinement.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{ textAlign:'left', flexShrink: 0, color: "orange" }}>
            How does SEO impact my website's visibility on search<br/> engines like Google?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            SEO directly influences your website's visibility on search engines by improving its ranking for relevant keywords, making it more likely to appear at the top of search results and attract organic traffic from users actively seeking your products or services.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel7"}
          onChange={handleChange("panel7")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{textAlign:'left', width: "100%", flexShrink: 0, color: "orange" }}>
            Do I need to invest in ongoing SEO efforts, or is it a one-time task?

            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            SEO is an ongoing process that requires consistent effort and adaptation to stay competitive in the ever-evolving digital landscape, as search engine algorithms and user behaviours change over time.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel6"}
          onChange={handleChange("panel6")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography sx={{textAlign:'left', width: "100%", flexShrink: 0, color: "orange" }}>
            What sets Vasmi Group apart in providing SEO services?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            Vasmi Group stands out in providing SEO services due to our comprehensive approach, strategic expertise, and commitment to delivering measurable results, tailored to your business goals and target audience, ensuring long-term success in the competitive online market.

            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="home-form">
        <div className="left-form-sec">
          <p className="home-left-form">SO What Next</p>
          <p className="lets-work-form">Let’s Work</p>
          <p className="not-sure-form">Not Sure Where To start?</p>
          <form method="post" className="form-hom" onSubmit={handleSubmit}>
          <div className="inp-error">

      <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
      {nameError && <p className="error">{nameError}</p>}
          </div>
          <div className="inp-error">

      <input type="text" placeholder="PhoneNumber" value={phone} onChange={(e) => setPhone(e.target.value)} />
      {phoneError && <p className="error">{phoneError}</p>}
      </div>
      <div className="inp-error">

      <input type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
      {emailError && <p className="error">{emailError}</p>}
      </div>
      <div className="inp-error">

      <select name="services" placeholder="Services Interested In" id="services" onChange={handleSelect}>
        <option value="">-- Select Service --</option> {/* Default option */}
        {services.map((serviceOption) => (
          <option key={serviceOption.value} value={serviceOption.value}>
            {serviceOption.label}
          </option>
        ))}
      </select>
      {serviceError && <p className="error">{serviceError}</p>}
      </div>
      <div className="inp-error">

      <textarea name="Message" id="#" cols="30" rows="10" placeholder="Message" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
      {messageError && <p className="error">{messageError}</p>}
      </div>
      <div className="successmeet">

      <button>Submit</button>
      {messageSuccess && <p className="success">{messageSuccess}</p>}
      </div>


    </form>
        </div>
        <div className="right-form-sec">
          <p className="left-work-form">Let’s Talk</p>
          <p className="call-form-home">+91-8142188880</p>
          <p className="call-form-home">+91-7095355556</p>
          <p className="mail-form-home">
            {" "}
            <img src={mail} alt="mail" />Info@vasmigroup.com
          </p>
        </div>
      </div>
     
      
    </div>
  );
}

export default Seo;
