import React, { useState, useEffect} from 'react';
import './category.css'; // Assuming the CSS file is named blogs.css
import { Link } from 'react-router-dom';
import blogim from '../../assets/blogim.png';
import blogsub from '../../assets/blogsub.png';
import arrowpng from '../../assets/arrowpng.svg';
import { Helmet } from 'react-helmet';
const MAX_EXCERPT_LENGTH = 150;
const MAX_TITLE_LENGTH = 25;

const Category = () => {
  const myStyle = { width: '334px', height: '238px' };
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const truncateExcerpt = (excerpt) => {
    if (excerpt.length > MAX_EXCERPT_LENGTH) {
      return excerpt.substring(0, MAX_EXCERPT_LENGTH) + '...';
    }
    return excerpt;
  };

  const truncateTitle = (title) => {
    if (title.length > MAX_TITLE_LENGTH) {
      return title.substring(0, MAX_TITLE_LENGTH);
    }
    return title;
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      const catname = window.location.pathname.slice(1).split('&')[0].split('=')[1]; // Extract ID from URL
      try {
        const response = await fetch(`https://www.vasmigroup.com/api/get_catposts.php?catname=${catname}`); // Replace with your API endpoint URL

        if (!response.ok) {
          throw new Error(`Error fetching posts: ${response.statusText}`);
        }

        const data = await response.json();
        setPosts(data);
      } catch (error) {
        setError(error.message);
        console.error('Error fetching posts:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

 

  return (
    <div className='blogs-vasmi'>
      <Helmet>
        <title>Category | Vasmi Group</title>
        <meta name="description" content="Vasmi Group's blog provides valuable insights, trends, and expert tips on digital marketing, web design, and other business success strategies." />
      </Helmet>

      <div className='blog-v-main'>
        <img src={blogim} alt='blogs' />
      </div>
      <div className='blogs-container'>
        {isLoading && <p className="loading-message">Loading posts...</p>}
        {error && <p className="error-message">Error: {error}</p>}
        {posts.length > 0 ? (
          posts.map((post) => (
            <div className='blogs-one-vasmi' key={post.id}> {/* Add key prop for performance */}
              <img
                src={`https://www.vasmigroup.com/admin/${post.blogimage}`} // Construct full image URL
                alt="blog"
                style={myStyle}
              />
              <p className='marketing-blogs'>{truncateTitle(post.title)}</p>
              <p className='marketing-blogs-para'>{truncateExcerpt(post.excerpt)}</p>
              <Link to={"/blogs/postid="+post.id} className='blogs-read-btn'>Read More<img src={arrowpng}  alt='arrow'/></Link>
            </div>
          ))
        ) : (
          <p>No posts found.</p>
        )}
      </div>
    </div>
  );
};

export default Category;
