import React from 'react';
import "./careers.css"
import diamond from "../../assets/diamond.svg"
import careerim from "../../assets/careerim.png"
import culture from "../../assets/culture.png"
import branding from "../../assets/branding.png"
import marketing from "../../assets/marketing.png"
import recruiting from "../../assets/recruiting.png"
import designers from "../../assets/designers.png"
import developers from "../../assets/developers.png"
import senior from "../../assets/senior.png"
import seniorgraphic from "../../assets/seniorgraphic.png"
import perks from "../../assets/perks.svg"
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { Helmet } from 'react-helmet';
const Careers = () => {
  return (
    <div className='career'>
      <Helmet>
        <title>IT Recruitment company in vizag | Vasmi Group</title>
        <meta name="description" 
        content="Join Vasmi Group's dynamic team and explore career opportunities, showcasing your skills in innovative projects and a vibrant workplace." />
      </Helmet>

      <img src={careerim} alt='doom' className='career-land'/>

      <div className='sde-sec-one'>
        <div className='sde-sec-one-left'>
            <p className='sde-sec-left-heading career-plus'>
            <strong>
            Our Culture
            </strong>

            </p>
            <p className='sde-sec-one-para'>
            At Vasmi Group, our culture thrives on innovation, collaboration, and relentless passion for digital  excellence. We cultivate an environment where creativity flourishes, ideas converge, and each team  member plays a vital role in our success story. Our collaborative spirit extends beyond projects,  fostering a supportive community that encourages continuous learning and growth. Embracing  diversity and inclusivity, we celebrate each milestone, creating a culture that not only drives  exceptional digital marketing results but also nurtures a fulfilling and inspiring workplace for  everyone involved in our journey.

            </p>

            <button className='sde-sec-one-btn btn-career'>
            For Candidates

            </button> 
        </div>
        <img src={culture} alt='sde' className='career-soft'/>
      </div>
      <div>
      <p className='about-sec-two-head'>
      The type of Innovators we look for
      </p>
        <div className='brands-career'>

          <img src={branding} alt='brand'/>
          <img src={developers} alt='brand'/>
          <img src={recruiting} alt='brand'/>
          <img src={marketing} alt='brand'/>
          <img src={designers} alt='brand'/>
        </div>
      </div>
      <div className='available-position'>
      <p className='about-sec-two-head'>
      Available Positions
      </p>

      <div className='positions-main' >
        <div className='position-career'>
    <img src={senior} alt='senior'/>
    <p className='senior-position'>Senior developer</p>
    <p className='senoir-experience'>Exp Level: <strong>3-5 Years</strong></p>
    <p className='career-skills'>Skills: <p>  HTML 5 , CSS , JavaScript , React , Reduct </p></p>

    <button className='position-btn'>Apply Now</button>
        </div>

        <div className='position-career'>
    <img src={seniorgraphic} alt='senior'/>
    <p className='senior-position'>Senior Graphic Designer</p>
    <p className='senoir-experience'>Exp Level: <strong>4-5 Years</strong></p>
    <p className='career-skills'>Skills: <p> Adobe Illustrator,3d design,Poster Desgin</p></p>

    <button className='position-btn'>Apply Now</button>
        </div>
      </div>



      </div>
      <p className='about-sec-two-head'>
      Our Perks
      </p>

      <div className='position-perks'>

      <div className='position-cover'>
      
      <div className='card-perk'>
        <img  src={perks} alt='perk' />
        <p className='perk-para'>Creative Freedom</p>
        
      </div>
      <p className='perk-para-career'>Explore boundless creativity in shaping digital campaigns, unleashing innovative  ideas to captivate audiences and redefine industry benchmarks, fostering professional growth and  recognition.
        
        </p>
       </div>
       <div className='position-cover'>
      
      <div className='card-perk'>
        <img  src={perks} alt='perk' />
        <p className='perk-para'>Continuous Learning</p>
        
      </div>
      <p className='perk-para-career'>Embrace a culture of perpetual growth, access to cutting-edge training  programs, and industry insights, fostering personal and professional development, ensuring mastery  in digital marketing.

        </p>
       </div>
       <div className='position-cover'>
      
      <div className='card-perk'>
        <img  src={perks} alt='perk' />
        <p className='perk-para'>Collaborative
Environment</p>
        
      </div>
      <p className='perk-para-career'>Thrive in a supportive ecosystem where teamwork reigns supreme,  fostering synergy between diverse talents, empowering collective success in dynamic digital  landscapes.

        </p>
       </div>
       <div className='position-cover'>
      
      <div className='card-perk'>
        <img  src={perks} alt='perk' />
        <p className='perk-para'>Client Diversity</p>
        
      </div>
      <p className='perk-para-career'>Engage with a diverse clientele spanning industries and geographies, gaining  invaluable exposure to multifaceted challenges, honing adaptive strategies and expanding  professional horizons.

        </p>
       </div>
       <div className='position-cover'>
      
      <div className='card-perk'>
        <img  src={perks} alt='perk' />
        <p className='perk-para'>Tech Savvy Atmosphere</p>
        
      </div>
      <p className='perk-para-career'>Immerse in a tech-driven environment, leveraging state-of-the-art tools and  platforms, stimulating creativity, and enhancing efficiency in crafting impactful digital campaigns.

        </p>
       </div>
       <div className='position-cover'>
      
      <div className='card-perk'>
        <img  src={perks} alt='perk' />
        <p className='perk-para'>Work-Life Balance</p>
        
      </div>
      <p className='perk-para-career'> Prioritize well-being with flexible schedules and remote work options, promoting  harmony between personal and professional commitments, ensuring a fulfilling and sustainable  career journey.

        </p>
       </div>

      </div>

      <div className='position-vision'>
      <div className='position-cover cover-flow'>
      
        <p className='perk-para'>Our Vision</p>
        
      <p className='perk-para-career'>At Vasmi Group, our vision is to be the architects of digital success, seamlessly blending innovation,  strategy, and expertise. We aspire to redefine business excellence by offering comprehensive digital  marketing, website development, and recruitment services. Our mission is to empower brands with a  dynamic online presence, crafted through cutting-edge websites and fuelled by strategic digital  marketing campaigns. We envision not just fulfilling but exceeding our client's expectations, creating  an ecosystem where businesses thrive, websites resonate, and teams flourish. Join us in navigating  the digital landscape, where success is not just a destination but a continuous, evolving journey.

        </p>
       </div>
       <div className='position-cover cover-flow'>
      
        <p className='perk-para'>Our Mission</p>
        
      <p className='perk-para-career'>At Vasmi Group, our mission is to catalyze your digital ascendancy through an integrated approach of  unparalleled digital marketing, bespoke website development, and strategic recruitment services.  We are committed to fostering your brand's online resonance, leveraging cutting-edge technologies  and innovative strategies. Our dedicated team strives to empower businesses with impactful digital  solutions, ensuring your digital footprint transcends expectations. Through adept website  development and astute recruitment, we aim to build not just brands, but sustainable ecosystems of  success.

        </p>
       </div>

      </div>
      <div className='about-sec-two'>
      <p className='about-sec-two-head'>
        Our Values
      </p>

      <div className='about-sec-sub-two'>
        <div className='about-sec-sub-two-one'>

        <img src={diamond} alt='about'/>

        <p className='about-sec-two-head-two'>
        Innovation
        </p>

        <p className='about-sec-two-head-three'>
        Igniting tech excellence, crafting avant-garde marketing, and pioneering recruitment  solutions that redefine industry standards, ensuring clients stay ahead in the digital and IT landscape.

        </p>

        </div>
        <div className='about-sec-sub-two-one'>

        <img src={diamond} alt='about'/>

        <p className='about-sec-two-head-two'>
        Collaboration
                </p>

        <p className='about-sec-two-head-three'>
        Fostering synergy between tech brilliance and strategic marketing, facilitating  seamless collaboration in our recruitment endeavors, building comprehensive solutions that drive  success for clients.

        </p>

        </div>
        <div className='about-sec-sub-two-one'>

        <img src={diamond} alt='about'/>

        <p className='about-sec-two-head-two'>
        Integrity
                </p>

        <p className='about-sec-two-head-three'>
        Upholding unwavering ethical standards, ensuring transparent communication, and  delivering trustworthy digital marketing, IT services, and recruitment processes that cultivate  enduring relationships and client satisfaction.

        </p>

        </div>
        <div className='about-sec-sub-two-one'>

        <img src={diamond} alt='about'/>

        <p className='about-sec-two-head-two'>
        Adaptability
                </p>

        <p className='about-sec-two-head-three'>
        Embracing the ever-evolving digital and IT landscape, we tailor agile strategies, ensuring  our clients remain adaptable and competitive in dynamic markets, driving sustainable success.

        </p>

        </div>
      </div>
      
     

      </div>
      <div className="form-recruit-r">
        <h1 className="platform-heading-r">Keen to work with Vasmi Group?</h1>

        <div className="recruit-formm">
          <h2 className="submit-recruit-p">Submit your CV</h2>
          <form action="">
            <div className="form-inps-recruit">
              <input type="text" placeholder="FirstName" />
              <input type="text" placeholder="LastName" />
              <input type="email" required placeholder="Email" />
              <input type="text" placeholder="PhoneNumber" />
              <input type="text" placeholder="Nationality" />
              <select name="select" id="">
                <option value="Primary Role Interested in">
                  Primary Role Interested in
                </option>
                <option value="Frontend Developer">frontend Developer</option>
                <option value="Full Stack Developer">
                  Full Stack Developer
                </option>
              </select>
              <input type="text" placeholder="Years Of Experience" />
              <input
                type="file"
                name="d"
                id="down"
                placeholder="upload Your Cv"
              />
            </div>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 28,
                        "& .css-1ikz1rn-MuiButtonBase-root-MuiCheckbox-root.Mui-checked":
                          {
                            color: "white",
                          },
                      },
                    }}
                  />
                }
                label="I consent to Handmade contacting me via the information that I have provided."
                sx={{
                  "& .css-ahj2mt-MuiTypography-root": {
                    fontSize: 14,
                    width: "300px",
                    color: "black",
                  },
                }}
              />
            </FormGroup>

            <button className="contact-btn r-btn">Submit</button>
          </form>
        </div>
      </div>
      
    </div>
  );
}

export default Careers;
