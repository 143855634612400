import React, { useEffect, useState } from "react";
import "./home.css";
import landvideo from "../../assets/landvideo.mp4";
import sound from "../../assets/sound.svg";
import business from "../../assets/business.svg";
import money from "../../assets/money.svg";
import checklist from "../../assets/Checklist.svg";
import digital from "../../assets/digital.svg";
import web from "../../assets/web.svg";
import recruit from "../../assets/recruiit.svg";
import graphic from "../../assets/graphic.svg";
import right from "../../assets/right.svg";
import androidImage from "../../assets/android.png";
import vasmilogo from "../../assets/sublogo.png";
import arrowpng from "../../assets/arrowpng.svg"
import blogsub from "../../assets/blogsub.png"
import angularImage from "../../assets/angular.png";
import codeigniteImage from "../../assets/codeignite.png";
import googleaiImage from "../../assets/googleai.png";
import hybridImage from "../../assets/hybrid.png";
import magnetoImage from "../../assets/magneto.png";
import phpImage from "../../assets/php.png";
import nodeImage from "../../assets/node.png";
import ios from "../../assets/ios.png";
import innovative from "../../assets/Innovative.png";

import work from "../../assets/work.png";
import frndly from "../../assets/frndly.png";
import pythonImage from "../../assets/python.png";
import pytorchImage from "../../assets/pytorch.png";
import reactnativeImage from "../../assets/reactnative.png";
import wordpress from "../../assets/wordpress.png";
import shopifyImage from "../../assets/shopify.png";
import mail from "../../assets/mail.svg";
import tensorflowImage from "../../assets/tensorflow.png";
import xamarinImage from "../../assets/xamarin.png";
import lando from "../../assets/lando.png";
import techlash from "../../assets/techlash.png";
import dileeptesto from "../../assets/dileeptesto.jpg";
import swathitesto from "../../assets/swathitesto.jpg";
import saitesto from "../../assets/saibabu.jpg";
import bhagavanbidda from "../../assets/bhagavanbidda.jpg";
import ex from "../../assets/ex.png";
import js from "../../assets/js.png";
import agv from "../../assets/agv.png";
import xdImage from "../../assets/xddd.png";
import leftarrow from "../../assets/leftarrow.svg";
import figmaImage from "../../assets/figma.svg";
import premierSvgImage from "../../assets/premier.svg";
import photoshop from "../../assets/photoshop.svg";
import safe from "../../assets/safe.svg";
import fee from "../../assets/fee.svg";
import support from "../../assets/support.svg";
import delivery from "../../assets/delivery.svg";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate,Link } from "react-router-dom";
import { Helmet } from 'react-helmet';


const MAX_TITLE_LENGTH = 20;
const MAX_EXCERPT_LENGTH = 150;
const truncateExcerpt = (excerpt) => {
  if (excerpt.length > MAX_EXCERPT_LENGTH) {
    return excerpt.substring(0, MAX_EXCERPT_LENGTH) + '...';
  }
  return excerpt;
};
const truncateTitle = (title) => {
  if (title.length > MAX_TITLE_LENGTH) {
    return title.substring(0, MAX_TITLE_LENGTH);
  }
  return title;
};

  const TextAnimation = ({ texts }) => {
  const [part, setPart] = useState('');
  const [index, setIndex] = useState(0);
  const [offset, setOffset] = useState(0);
  const [forwards, setForwards] = useState(true);
  const [skipCount, setSkipCount] = useState(0);
  const [skipDelay] = useState(15);
  const [speed] = useState(70);


  useEffect(() => {
    const interval = setInterval(() => {
      if (forwards) {
        if (offset >= texts[index].length) {
          setSkipCount((prevSkipCount) => prevSkipCount + 1);
          if (skipCount === skipDelay) {
            setForwards(false);
            setSkipCount(0);
          }
        }
      } else {
        if (offset === 0) {
          setForwards(true);
          setIndex((prevIndex) => (prevIndex + 1) % texts.length);
          setOffset(0);
        }
      }
      const newPart = texts[index].substr(0, offset);
      if (skipCount === 0) {
        if (forwards) {
          setOffset((prevOffset) => prevOffset + 1);
        } else {
          setOffset((prevOffset) => prevOffset - 1);
        }
      }
      setPart(newPart);
    }, speed);

    return () => clearInterval(interval);
  }, [forwards, index, offset, skipCount,skipDelay, speed, texts]);

  return <div className="worden features-left">{part}</div>;
};

export const HomeScreen = () => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate()
  const [currentIndex, setCurrentIndex] = useState(0);

  
  const testimonials = [
    {
      name: 'Dileep',
      designation: 'Frontend Developer',
      image: dileeptesto,
      text: "  As a front-end developer at Vasmi Group, I've had the privilege of contributing to innovative projects that have redefined digital experiences in Visakhapatnam. Vasmi Group's commitment to excellence and fostering a collaborative work environment has been instrumental in my professional growth. I'm proud to be a part of a company that prioritizes employee satisfaction and delivering exceptional results to clients."
    },
    {
      name: 'Bhagavan',
      designation: 'Senior Developer',
      image: bhagavanbidda,
      text: "In my role as a senior developer at Vasmi Group, I've witnessed firsthand the company's unwavering dedication to driving growth and innovation in Visakhapatnam's digital landscape. Vasmi Group's emphasis on fostering a culture of learning and collaboration has enabled me to expand my skill set and take on new challenges with confidence. I'm grateful to be a part of a team that values excellence and empowers its employees to thrive."
    },
    {
      name: 'Venkat Sai',
      designation: 'Ui& Ux Designer',
      image: saitesto,
      text: " As a UI/UX developer at Vasmi Group, I've had the opportunity to work on projects that push the boundaries of creativity and innovation. Vasmi Group's supportive work environment and emphasis on professional development have enabled me to grow both personally and technically. I'm proud to be a part of a company that prioritises employee well-being and encourages us to strive for excellence in everything we do."
    },
  ];

  const handleLeftClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1));
  };

  const handleRightClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

    const [words] = useState([
    'Check our',
    'World class',
    'Features',
    
  ]);

 //let us talk form code begin

 const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [service, setService] = useState('');
  const [message, setMessage] = useState('');
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [serviceError, setServiceError] = useState('');
  const [messageError, setMessageError] = useState('');
  const [messageSuccess, setMessageSuccess] = useState('');
  const [recentPosts, setRecentPosts] = useState([]); // State for recent posts data

  const services = [
    { value: 'branding analysis', label: 'Branding Analysis' },
    { value: 'graphic-designer', label: 'Graphic Designer' },
    { value: 'digital-marketing', label: 'Digital Marketing' },
    { value: 'new business packages', label: 'New Business Packages' },
    { value: 'web development', label: 'Web Development' },
    { value: 'app development', label: 'App Development' },
  ];

  const handleSelect = (event) => {
    setService(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Clear previous errors
    setNameError('');
    setEmailError('');
    setPhoneError('');
    setServiceError('');
    setMessageError('');

    // Validation checks
    if (!name.trim()) {
      setNameError('Please enter your name.');
      return;
    }

    if (!email.trim()) {
      setEmailError('Please enter your email.');
      return;
    }

    // Email validation using regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError('Please enter a valid email address.');
      return;
    }

    if (!phone.trim()) {
      setPhoneError('Please enter your phone number.');
      return;
    }

    // Phone number validation using regular expression
    const phoneRegex = /^[0-9]{10}$/;
    if (!phoneRegex.test(phone)) {
      setPhoneError('Please enter a valid 10-digit phone number.');
      return;
    }

    if (!service) {
      setServiceError('Please select a service.');
      return;
    }

    if (!message.trim()) {
      setMessageError('Please enter your message.');
      return;
    }

   // If all validations pass, proceed with form submission
   const data = { name, email, phone, service, message };
   
   setMessageSuccess("We will get back to you")
   console.log('Form data:', data);
   // Continue with form submission or API call

   try {
     const response = await fetch('https://www.vasmigroup.com/api/homepage-form.php', {
       method: 'POST',
       headers: { 'Content-Type': 'application/json' },
       body: JSON.stringify(data),
       mode: 'no-cors'
     });
     const result = await response.json();
     console.log('Form submission result:', result);
   } catch (error) {
     console.error('Error submitting form:', error.message);
   }


   setName('');
     setEmail('');
     setPhone('');
     setService('');
     setMessage('');

 };
//letus talk form code end
const myStyle = { width: '334px', height: '238px' };

useEffect(() => {
  const fetchData = async () => {
     // Fetch recent blog posts (assuming separate API endpoint)
     const recentResponse = await fetch(`https://www.vasmigroup.com/api/get_posts.php`);
     const recentData = await recentResponse.json();
     setRecentPosts(recentData.slice(0, 3)); // Limit to 3 recent posts
  };

  fetchData();
}, []);

  return (
    
    <div className="home">
  <Helmet>
    <title>Vasmi Group | Best Digital Marketing company in Vizag</title>
    <meta name="description" content="Vasmi Group, top advertising agency in Vizag, offers creative services in SEO,SMM,SEM, Graphic Design, UI/UX Design, Web Design, App Development, IT Recruitment" />
  </Helmet>

      <video className="landvideo" autoPlay loop muted>
        <source src={landvideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="hero-sec">
        <p className="pop-outin head-animation"><strong>Smart</strong> Buisness </p>
        <p className="pop-outin head-animation">With <strong>Smart</strong> people</p>
        <p className="grow-hero">
          Grow your business to the next level to improve your business
          performance to stay competitive
        </p>
        <div className="btn-group">
          <button className="one-btn" onClick={()=>navigate('/digital-marketing')}>Get Started</button>
          <button className="two-btn" onClick={()=>navigate('/web-designing')}>Web Portfolio</button>
        </div>

        <div className="count-home">
          <div className="count-one">
            <p className="count">10+</p>
            <p className="sub-count">Years Experience</p> 
          </div>
          <div className="count-one">
            <p className="count">891+</p>
            <p className="sub-count">Cases Solved</p>
          </div>
          <div className="count-one">
            <p className="count">260+</p>
            <p className="sub-count">Business Partners</p>
          </div>
        </div>

        <div className="cards-home">
          <div className="card-one">
            <img src={sound} alt="sound" />
            <p className="card-head">Digital Marketing</p>
            <p className="card-sub-info">
            Increasing your visibility with the strategic marketing approach</p>
          </div>
          <div className="card-one">
            <img src={money} alt="sound" />
            <p className="card-head">Recruiting</p>
            <p className="card-sub-info">
            Setting up your digital office for the brand visibility to shine</p>
          </div>
          <div className="card-one">
            <img src={checklist} alt="sound" />
            <p className="card-head">Graphic Designing</p>
            <p className="card-sub-info">
            Captivating visuals to convey all those unspoken expressions
                        </p>
          </div>
          <div className="card-one">
            <img src={business} alt="sound" />
            <p className="card-head">Website Designing</p>
            <p className="card-sub-info">
            Fill out the business staff with the potential candidates.
                        </p>
          </div>
        </div>
      </div>
      <div className="section-two">
        <div className="sec-left">
          <a className="vasmi-btn" href="https://www.instagram.com/vasmigroup/" target="_blank">Follow Vasmi Group</a>
          <TextAnimation texts={words}/>
         
        </div>
        <div className="features-right">
          <div className="cords">
            <p className="number">01</p>
            <p className="subject-right">Stratagical Approach</p>
          </div>
          <div className="cords">
            <p className="number">02</p>
            <p className="subject-right">Following Industrial Trends</p>
          </div>
          <div className="cords">
            <p className="number">03</p>
            <p className="subject-right">Capticating Designs</p>
          </div>
          <div className="cords">
            <p className="number">04</p>
            <p className="subject-right">Visibility On Digital Platforms</p>
          </div>
        </div>
      </div>
      <div className="section-three">
        <h1 className="services-three">Our Services</h1>

        <div class="disappear">
  <span>We provide </span> <span>great </span> <span>services </span> <span>for </span> <span> our </span> <span>customers </span> <span>based </span> <span>on </span> <span>needs</span>
</div>
        <div className="sec-three-cards">
          <div className="card-sec-services ">
            <img src={digital} alt="digital" className="service-img" />
            <p className="card-head digi">Digital Marketing</p>
            <p className="card-sub-info subb">
            Boost your online presence with our cutting-edge digital marketing services. From SEO to social media, we enhance your brand's visibility and engagement

            </p>
            <p className="imgs" onClick={()=>navigate('/digital-marketing')}>
              Learn More
              <img src={right} alt="right" />
            </p>
          </div>

          <div className="card-sec-services ser-one">
            <img src={web} alt="digital" className="service-img" />
            <p className="card-head digi">Web Designing</p>
            <p className="card-sub-info subb">
              Elevate your brand with our bespoke website designs. We create visually stunning and user-friendly websites tailored to your unique business identity.

            </p>
            <p className="imgs" onClick={()=>navigate('/web-designing')}>
              Learn More
              <img src={right} alt="right" />
            </p>
          </div>
          <div className="card-sec-services ser-two">
            <img src={recruit} alt="digital" className="service-img" />
            <p className="card-head digi"> Recruitment</p>
            <p className="card-sub-info subb">
              Transform ideas into captivating visuals with our graphic design services. From logos to marketing materials, we bring your brand to life

            </p>
            <p className="imgs" onClick={()=>navigate('/recruitment')}>
              Learn More
              <img src={right} alt="right" />
            </p>
          </div>
          <div className="card-sec-services ser-three">
            <img src={graphic} alt="digital" className="service-img" />
            <p className="card-head digi">Graphic Designing</p>
            <p className="card-sub-info subb">
             Discover top talent seamlessly with our recruitment services. We match skilled professionals to your business needs, ensuring a perfect fit
             
            </p>
            <p className="imgs" onClick={()=>navigate('/graphic-designing')}>
              Learn More
              <img src={right} alt="right" />
            </p>
          </div>
        </div>
      </div>

      <div className="bar">
        <p className="services-three proces">Our Process</p>
        <p className="we-care">
          We <strong className="care">CARE</strong> for your growth
        </p>
       

        <div className="creative-care">
          <div className="creative">
            <p className="create">Creative</p>
            <p className="create-sub">
              We just don’t create a websites, we bring in a experience that
              converts visitors into your customers. With proficient thought-
              process of our Creative designers, we deliver dynamic and
              world-class designs.
            </p>
            <p className="trans-one">01</p>
          </div>
          <div className="creative cr-one">
            <p className="create">Approach</p>
            <p className="create-sub">
              Our primary objective is to understand your needs and deliver
              beyond your expectations. To achieve this goal, we discuss,
              improvise and suggest the best and customized solutions for your
              business.
            </p>
            <p className="trans-one two">02</p>
          </div>
          <div className="creative cr-two">
            <p className="create">Relevant</p>
            <p className="create-sub">
              We understand that User Interests are the primary concern for your
              business. That’s why we focus on only relevant and
              customer-centric web development services.
            </p>
            <p className="trans-one">03</p>
          </div>
          <div className="creative cr-three">
            <p className="create">Experts</p>
            <p className="create-sub">
              The thing which makes Maven Cluster a leading IT Firm in this
              Industry is its Incredible Team. We hire only the Top 1% of the
              exceptionally skilled developers for you. 80% Client Retention is
              the result of trust of our clients.
            </p>
            <p className="trans-one tran-four">04</p>
          </div>
        </div>
      </div>
      <div className="our-tools">
        <p className="services-three proces">Technology We Use</p>
        <h2>To empower your business</h2>

        <div className="technology-tools">
          {images.map((path, index) => (
            <div className="tech-imgs" style={{ width: "200px", height: "100px" }}>
              <img
                key={index}
                src={path}
                className="tool"
                alt={`Imag ${index + 1}`}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="why-choose">
        <p className="why-choose-head">
          Why Choose <strong className="care">Vasmi Group</strong>?
        </p>
        <div className="choose-section">
          <div className="choose-one">
            <div className="imag">
              <img src={safe} alt="safe" />
            </div>
            <div className="choose-right-sec">
              <p className="right-sec-head">Safe</p>
              <p className="right-sec-sub">
              Rest easy with us. Our commitment to robust security protocols
ensures your digital assets and data are protected, 
providing a safe environment for your online success.
              </p>
            </div>
          </div>
          <div className="choose-one ">
            <div className="imag im-col">
              <img src={delivery} alt="safe" />
            </div>
            <div className="choose-right-sec">
              <p className="right-sec-head">Fast</p>
              <p className="right-sec-sub">
              Rev up your digital journey with our swift and dynamic approach.
              From rapid campaigns to agile strategies, 
              we excel in delivering fast, impactful results.
              </p>
            </div>
          </div>
          <div className="choose-one ">
            <div className="imag im-fee">
              <img src={fee} alt="safe" />
            </div>
            <div className="choose-right-sec">
              <p className="right-sec-head">Low Fee</p>
              <p className="right-sec-sub">
              Experience top-notch digital marketing without breaking the bank.
              Our cost-effective solutions ensure maximum value, 
              empowering your business without a hefty price tag.
              </p>
            </div>
          </div>
          <div className="choose-one ">
            <div className="imag im-support">
              <img src={support} alt="safe" />
            </div>
            <div className="choose-right-sec">
              <p className="right-sec-head">Quick Support</p>
              <p className="right-sec-sub">
              We've got your back. Our dedicated support team is ready to assist you promptly,
              providing quick solutions and ensuring your digital experience is seamless.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="cools-section">
        <p className="services-three proces">Our Tools</p>
        <div className="sec-cool">
          <img src={innovative} alt="innovative" />
          <img src={frndly} alt="innovative" />
          <img src={work} alt="innovative" />
          <img src={innovative} alt="innovative" />
          <img src={frndly} alt="innovative" />
        </div>
      </div>
     
        <div className="testmonials">
      <div className="left-testmo">
        <div className="inner-testo">
          <img src={testimonials[currentIndex].image} alt={testimonials[currentIndex].name} />
        </div>
      </div>
      <div className="right-testo">
        <div className="top-sec-right">
          <p className="testmon">Testimonials</p>
          <img src={vasmilogo} alt="vasmi" />
        </div>
        <p className="they-say">What They Say</p>
        <p className="quotes-testo">“</p>
        <p className="right-testo-para">
          {testimonials[currentIndex].text}
        </p>
        <p className="swathi-testo">{testimonials[currentIndex].name}</p>
        <p className="content-testo">{testimonials[currentIndex].designation}</p>
        <div className="testo-btns">
          <button className="left-btn-testo" onClick={handleLeftClick}>
           <img src={leftarrow} alt="testo"/>
          </button>
          <button className="left-btn-testo" onClick={handleRightClick}>
          <img src={arrowpng} alt="testo"/>

          </button>
        </div>
      </div>
    </div>

      <div className="clients">
        <div className="cli">
          <img src={agv} alt="agv" />
          <img src={lando} alt="agv" />
          <img src={techlash} alt="agv" />
          <img src={js} alt="agv" />
          <img src={ex} alt="agv" />
          <img src={agv} alt="agv" />
          <img src={techlash} alt="agv" />
          <img src={js} alt="agv" />
          <img src={ex} alt="agv" />
        </div>
      </div>
      <p className="services-three proces">Blogs</p>

      <div className='blogs-container'>
      {recentPosts.map((recentPost) => (
<div className='blogs-one-vasmi'>
 <img src={`https://www.vasmigroup.com/admin/${recentPost.blogimage}`}  alt="blog" style={myStyle}  />
  <p className='marketing-blogs'>{truncateTitle(recentPost.title)}</p>
  <p className='marketing-blogs-para'>{truncateExcerpt(recentPost.excerpt)}</p>
  <Link to={"/blogs/postid="+recentPost.id} className='blogs-read-btn'>Read More<img src={arrowpng}  alt='arrow'/></Link>

</div>
))}
</div>
      <div className="home-form">
        <div className="left-form-sec">
          <p className="home-left-form">SO What Next</p>
          <p className="lets-work-form">Let’s Work</p>
          <p className="not-sure-form">Not Sure Where To start?</p>
          <form method="post" className="form-hom" onSubmit={handleSubmit}>
          <div className="inp-error">

      <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
      {nameError && <p className="error">{nameError}</p>}
          </div>
          <div className="inp-error">

      <input type="text" placeholder="PhoneNumber" value={phone} onChange={(e) => setPhone(e.target.value)} />
      {phoneError && <p className="error">{phoneError}</p>}
      </div>
      <div className="inp-error">

      <input type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
      {emailError && <p className="error">{emailError}</p>}
      </div>
      <div className="inp-error">

      <select name="services" placeholder="Services Interested In" id="services" onChange={handleSelect}>
        <option value="">-- Select Service --</option> {/* Default option */}
        {services.map((serviceOption) => (
          <option key={serviceOption.value} value={serviceOption.value}>
            {serviceOption.label}
          </option>
        ))}
      </select>
      {serviceError && <p className="error">{serviceError}</p>}
      </div>
      <div className="inp-error">

      <textarea name="Message" id="#" cols="30" rows="10" placeholder="Message" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
      {messageError && <p className="error">{messageError}</p>}
      </div>
      <div className="successmeet">

      <button>Submit</button>
      {messageSuccess && <p className="success">{messageSuccess}</p>}
      </div>


    </form>
        </div>
        <div className="right-form-sec">
          <p className="left-work-form">Let’s Talk</p>
          <p className="call-form-home">+91-8142188880</p>
          <p className="call-form-home">+91-7095355556</p>
          <p className="mail-form-home">
            {" "}
            <img src={mail} alt="mail" />Info@vasmigroup.com
          </p>
        </div>
      </div>
      <p className="services-three proces">FAQ's</p>

      <div className="accord">
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{textAlign:'left', width: "100%", flexShrink: 0, color: "orange" }}>
            What services does Vasmi Group offer in Visakhapatnam?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            At Vasmi Group, we provide a comprehensive range of services tailored to meet 
            the diverse needs of businesses in Visakhapatnam. Our offerings include recruitment 
            solutions to find top-tier talent, IT services for technology empowerment, and cutting-edge 
            digital marketing strategies to captivate your audience. Whether you're seeking advertising 
            agencies in Visakhapatnam or digital marketing companies in Vizag, we've got you covered.

            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{textAlign:'left', width: "100%", flexShrink: 0, color: "orange" }}>
            How can SEO benefit my business in Visakhapatnam?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            SEO, or Search Engine Optimization, is instrumental in enhancing your 
            business's online visibility in Visakhapatnam's competitive market landscape.
             By strategically optimizing your website with relevant keywords such as
              "advertising agencies in Visakhapatnam" or "digital marketing companies in Vizag," 
              you increase your chances of appearing prominently in search engine results. 
            This not only drives traffic to your website but also boosts brand awareness and customer engagement.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography sx={{ textAlign:'left', flexShrink: 0, color: "orange" }}>
            Why choose Vasmi Group for digital marketing services <br/>in Vizag?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            Vasmi Group stands out as a pioneering leader in delivering comprehensive digital marketing services in Vizag. Our team of seasoned professionals combines technical expertise with creative prowess to craft bespoke strategies tailored to your business's unique needs. From social media marketing to search engine optimization (SEO) and beyond, we're committed to driving growth and innovation for businesses across diverse industries in Visakhapatnam.

            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{ textAlign:'left', flexShrink: 0, color: "orange" }}>
            How does Vasmi Group ensure the success of recruitment <br/>
            solutions in Visakhapatnam?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            At Vasmi Group, we understand that finding top-tier talent is crucial for the success of your business in Visakhapatnam. That's why we employ a strategic approach to recruitment, leveraging our extensive network and industry expertise to connect you with the best candidates. Whether you're looking for advertising agencies in Vizag or IT professionals in Visakhapatnam, our recruitment solutions are designed to meet your specific requirements and drive growth for your business.

            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel7"}
          onChange={handleChange("panel7")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{textAlign:'left', width: "100%", flexShrink: 0, color: "orange" }}>
            What sets Vasmi Group apart from other advertising agencies in Visakhapatnam?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            Vasmi Group distinguishes itself as a trusted partner in navigating the complex landscape of advertising and marketing in Visakhapatnam. Our team combines creativity with strategic insight to deliver innovative solutions that captivate audiences and drive results. With a deep understanding of the local market dynamics and a commitment to excellence, we stand ready to propel your business toward new heights of success.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel6"}
          onChange={handleChange("panel6")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography sx={{textAlign:'left', width: "100%", flexShrink: 0, color: "orange" }}>
            How can I get started with Vasmi Group's services in Visakhapatnam?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            Getting started with Vasmi Group's services in Visakhapatnam is simple. You can reach out to us through our website or contact us directly to schedule a consultation. Our team will work closely with you to understand your goals and objectives and develop a customized strategy to meet your needs. Whether you're looking for digital marketing solutions, recruitment services, or IT solutions, Vasmi Group is here to be your trusted partner on the journey to excellence.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

const images = [
  androidImage,
  angularImage,
  codeigniteImage,
  googleaiImage,
  hybridImage,
  magnetoImage,
  phpImage,
  nodeImage,
  pythonImage,
  photoshop,
  pytorchImage,
  wordpress,
  ios,
  reactnativeImage,
  premierSvgImage,
  tensorflowImage,
  xamarinImage,
  shopifyImage,
  
  figmaImage,
  xdImage,
];
