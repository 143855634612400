import { Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./components/footer/Footer";
import { Header } from "./components/header/Header";
import Contact from "./screens/contact/Contact";
import { HomeScreen } from "./screens/home/HomeScreen";
import { Recruiting } from "./screens/recruiting/Recruiting";
import { Sem } from "./screens/sem/Sem";
import Smm from "./screens/smm/Smm";
import DigitalMarketing from "./screens/digitalMarketing/DigitalMarketing";
import Seo from "./screens/seo/Seo";
import SoftwareDevelopment from "./screens/software/SoftwareDevelopment";
import GraphicDesign from "./screens/graphic/GraphicDesign";
import About from "./screens/about/About";
import Careers from "./screens/careers/Careers";
import Blogs from "./screens/blogs/Blogs";
import Blog from "./screens/blog/Blog";
import Category from "./screens/category/Category";

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/sem-services" element={<Sem />} />
        <Route path="/recruitment" element={<Recruiting />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/smm-services" element={<Smm />} />
        <Route path="/digital-marketing" element={<DigitalMarketing />} />
        <Route path="/seo-services" element={<Seo />} />
        <Route path="/web-designing" element={<SoftwareDevelopment />} />
        <Route path="/graphic-designing" element={<GraphicDesign />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/blogs/:postid" element={<Blogs />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/category/:catname" element={<Category />} />
        <Route path="/career" element={<Careers />} />
      </Routes>
      <Footer />
    </div>
  );
} 

export default App;
