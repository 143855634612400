import React, { useEffect, useState } from 'react'
import "./digital.css"
import dgv from "../../assets/dgv.mp4"
import mort from "../../assets/email.png"
import lando from "../../assets/lando.png";
import techlash from "../../assets/techlash.png";
import ex from "../../assets/ex.png";
import js from "../../assets/js.png";
import seo from "../../assets/seo.svg"
import mail from "../../assets/mail.svg";
import agv from "../../assets/agv.png";
import digitree from "../../assets/digitree.png"
import arrowcircle from "../../assets/Vectorc.png"
import seoo from "../../assets/seo2.svg"
import facebook from "../../assets/facebook.svg"
import funnel from "../../assets/funnel.svg"
import funn from "../../assets/funn.svg"
import blog from "../../assets/blog.svg"
import percentage from "../../assets/percentage.svg"
import marketing from "../../assets/marketing.svg"
import ads from "../../assets/ads.svg"
import lead from "../../assets/lead.svg"
import payper from "../../assets/payper.svg"
import searchlocation from "../../assets/searchlocation.svg"
import locationn from "../../assets/location.svg"
import report from "../../assets/report.svg"
import analysis from "../../assets/analysis.svg"
import capitan from "../../assets/tick.svg"
import proceess from "../../assets/process.svg"
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const DigitalMarketing = () => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate()

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };




    useEffect(() => {
        const spans = document.querySelectorAll('.word span');
    
        let idx = 0;
        const intervalId = setInterval(() => {
          spans.forEach(span => span.classList.remove('active'));
    
          spans[idx].classList.add('active');
    
          idx = (idx + 1) % spans.length;
        }, 2000);
    
        return () => clearInterval(intervalId);
      }, []);
    


 //let us talk form code begin

 const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [service, setService] = useState('');
  const [message, setMessage] = useState('');
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [serviceError, setServiceError] = useState('');
  const [messageError, setMessageError] = useState('');
  const [messageSuccess, setMessageSuccess] = useState('');

  const services = [
    { value: 'branding analysis', label: 'Branding Analysis' },
    { value: 'graphic-designer', label: 'Graphic Designer' },
    { value: 'digital-marketing', label: 'Digital Marketing' },
    { value: 'new business packages', label: 'New Business Packages' },
    { value: 'web development', label: 'Web Development' },
    { value: 'app development', label: 'App Development' },
  ];

  const handleSelect = (event) => {
    setService(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Clear previous errors
    setNameError('');
    setEmailError('');
    setPhoneError('');
    setServiceError('');
    setMessageError('');

    // Validation checks
    if (!name.trim()) {
      setNameError('Please enter your name.');
      return;
    }

    if (!email.trim()) {
      setEmailError('Please enter your email.');
      return;
    }

    // Email validation using regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError('Please enter a valid email address.');
      return;
    }

    if (!phone.trim()) {
      setPhoneError('Please enter your phone number.');
      return;
    }

    // Phone number validation using regular expression
    const phoneRegex = /^[0-9]{10}$/;
    if (!phoneRegex.test(phone)) {
      setPhoneError('Please enter a valid 10-digit phone number.');
      return;
    }

    if (!service) {
      setServiceError('Please select a service.');
      return;
    }

    if (!message.trim()) {
      setMessageError('Please enter your message.');
      return;
    }

    // If all validations pass, proceed with form submission
    const data = { name, email, phone, service, message };
    setMessageSuccess("We will get back to you")
    console.log('Form data:', data);

    // Continue with form submission or API call
     // Continue with form submission or API call
   try {
    const response = await fetch('https://www.vasmigroup.com/api/software-form.php', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
      mode: 'no-cors'
    });

    const result = await response.json();
    console.log('Form submission result:', result);
  } catch (error) {
    console.error('Error submitting form:', error.message);
  }

 // Continue with form submission or API call
 setName('');
 setEmail('');
 setPhone('');
 setService('');
 setMessage('');
  };
 //letus talk form code end



  return (
    <div className='dmg'>
      <Helmet>
        <title>Digital Marketing company in vizag | Vasmi Group</title>
        <meta name="description" content="Vasmi Group offers customized digital marketing strategies in SEO, SMM, SEM, and more for business growth." />
      </Helmet>
    <div className='dgv-main'>


      <video className="landvideo" style={{width:"100%"}} autoPlay loop muted>
        <source src={dgv} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    <div className='dmg-main'>

    <div class="word">
  <span>D</span>
  <span>I</span>
  <span>G</span>
  <span>I</span>
  <span>T</span>
  <span>A</span>
  <span>L</span>
  <span>...</span>
  <span>M</span>
  <span>A</span>
  <span>R</span>
  <span>K</span>
  <span>E</span>
  <span>T</span>
  <span>I</span>
  <span>N</span>
  <span>G</span>

</div>
    

    </div>
    </div>
      <div className='dgv-sec-one'>
        <p className='dgv-sec-head-one'>
        Boost Your Business with Expert  <strong>Digital Marketing in Vizag</strong> 
        </p>
        <p className='dgv-sec-para' >
        Maximize your business potential with our exclusive digital marketing services tailored specifically for your business. From strategic planning to targeted campaigns, we deliver results-driven solutions that propel your brand forward in the competitive digital landscape. Experience unparalleled success with our expertise.

        </p>

        <div className='dmv-sec-two'>
          <div className='dmv-sec-left'>
            <div className='dmg-sec-onepart'>
                <p className='dmv-sec-sec croma'>
              Searching Advertising
              </p>
              <p className='dmv-sec-paraa croma'>
              Optimize your online visibility with strategic Search Engine Advertising. Reach your target audience effectively and increase brand awareness for better conversions.

              </p>
            </div>
            <div className='dmg-sec-onepart'>
                <p className='dmv-sec-sec croma'>
                Paid Advertising
              </p>
              <p className='dmv-sec-paraa croma'>
              Accelerate your online presence with Paid Social Media Advertising. Target specific demographics on platforms like Facebook and Instagram to expand reach and drive conversions.
              
              </p>
            </div>
          </div>
          <div className='dmv-sec-middle'>
          <img src={mort} alt='email' />
          </div>
          <div className='dmv-sec-last'>
          <div className='dmg-sec-onepart '>
                <p className='dmv-sec-sec crom'>
                Display Advertising
              </p>
              <p className='dmv-sec-paraa crom'>
              Attract attention and engage your audience through Display Advertising. Utilize visual ads across websites and apps to boost brand visibility and drive conversions.

              </p>
            </div>
            <div className='dmg-sec-onepart '>
                <p className='dmv-sec-sec crom'>
                Video Advertising
              </p>
              <p className='dmv-sec-paraa crom'>
              Incorporate dynamic Video Advertising into your marketing strategy. Capture audience attention with engaging

              </p>
            </div>
          </div>
        </div>
      </div>
      
      <div className='dgv-sec-four-main'>
        <img src={digitree}  alt='digitree'/>
        <div className='dgv-sec-four'>
          <p className='dgv-sec-four-heading'>
          Effective Tactics for Maximizing Your Online Presence
          </p>
          <p className='dgv-sec-four-para'>
          Discover actionable methods to enhance your online visibility. Learn practical approaches for boosting brand awareness and driving conversions on digital platforms.

          </p>

          <div className='dgv-sec-four-arrow'>
            <p className='right-arrow-dgv'><img src={arrowcircle} alt='arrow' /> Search Engine Optimization</p>
            <p className='right-arrow-dgv'><img src={arrowcircle} alt='arrow' />Google Ads & PPC</p>
            <p className='right-arrow-dgv'><img src={arrowcircle} alt='arrow' /> Social Media Marketing</p>
            <p className='right-arrow-dgv'><img src={arrowcircle} alt='arrow' />Influencer Marketing</p>
            <p className='right-arrow-dgv'><img src={arrowcircle} alt='arrow' /> Creatives & Graphic Design</p>
            <p className='right-arrow-dgv'><img src={arrowcircle} alt='arrow' /> Website Designing</p>
          </div>
        </div>
      </div>
      <div className='dgv-sec-five'>
      <p className='dgv-sec-head-one'>
      We're your complete <strong>digital marketing solution</strong> , catering to all growth needs
        </p>
        <p>
        Comprehensive digital marketing agency specializing in fulfilling all your growth requirements.
        </p>
        <div className='dgv-sec-five-blog-cards'>
          
          <div className='five-blog-cards'>
            <p className='dgv-sec-five-card-head' >
            SEO Services 
            </p>
            <p className='dgv-sec-five-card-para'> 
            Our SEO services elevate your online presence by optimizing your website for search engines. We employ cutting-edge techniques, including keyword research, on-page optimization, and link building, to improve organic visibility and drive targeted traffic. Stay ahead of the competition and achieve sustainable growth with our results-driven SEO solutions.
            </p>
            <button className='dgv-sec-five-btn' onClick={()=>navigate('/seo-services')}>
              Learn More
            </button>
          </div>
          <div className='five-blog-cards'>
            <p className='dgv-sec-five-card-head'>
            SEM Services 
            </p>
            <p className='dgv-sec-five-card-para'> Our SEM services propel your business to the forefront of search engine results pages (SERPs). We ensure maximum visibility for your brand through strategic Pay-Per-Click (PPC) campaigns and ad placement. With targeted keyword bidding and data-driven optimization, we drive quality traffic and deliver measurable results that exceed expectations. </p>
            <button className='dgv-sec-five-btn' onClick={()=>navigate('/sem-services')}>
              Learn More
            </button>
          </div>
          <div className='five-blog-cards'>
            <p className='dgv-sec-five-card-head'>
            SMM Services 
            </p>
            <p className='dgv-sec-five-card-para'> Social Media Marketing is the cornerstone of digital brand engagement. We harness the power of platforms like Facebook, Instagram, and Twitter to connect businesses with their target audience. From compelling content creation to strategic ad campaigns, we drive growth and foster meaningful relationships in the ever-evolving landscape of social media.
            </p>
            <button className='dgv-sec-five-btn' onClick={()=>navigate('/smm-services')}>
              Learn More
            </button>
          </div>
         
          <div className='five-blog-cards'>
            <p className='dgv-sec-five-card-head'>
            Web Development 
            </p>
            <p className='dgv-sec-five-card-para'> 
            Unlock the potential of your online presence with our expert web development services. We craft custom websites tailored to your brand's unique identity and objectives. From sleek designs to seamless functionality, we ensure a user-friendly experience that captivates visitors and drives conversions. Elevate your digital presence with our professional web development solutions.
            </p>
            <button className='dgv-sec-five-btn' onClick={()=>navigate('/web-designing')}>
              Learn More
            </button>
          </div>
          <div className='five-blog-cards'>
            <p className='dgv-sec-five-card-head'>
            App Development 
            </p>
            <p className='dgv-sec-five-card-para'> 
            Discover the power of mobile with our cutting-edge app development services. We specialize in creating intuitive and engaging applications that elevate user experience and drive business growth. From concept to deployment, our team ensures seamless functionality across platforms, delivering innovative solutions that captivate audiences and maximize your brand's reach.
            </p>
            <button className='dgv-sec-five-btn' onClick={()=>navigate('/web-designing')}>
              Learn More
            </button>
          </div>
          <div className='five-blog-cards'>
            <p className='dgv-sec-five-card-head'>
            Brand Advertising 
            </p>
            <p className='dgv-sec-five-card-para'>               
            Maximize your reach and impact with our comprehensive online advertising solutions. From display ads to social media promotions, we do digital marketing in Vizag and beyond for businesses to achieve their digital marketing goals. Harness the power of targeted campaigns to elevate your brand's visibility and drive results in the competitive online landscape.
            </p>
            <button className='dgv-sec-five-btn'  onClick={()=>navigate('/seo-services')}>
              Learn More
            </button>
          </div>
          <div className='five-blog-cards'>
            <p className='dgv-sec-five-card-head'>
            UI/UX Services 
            </p>
            <p className='dgv-sec-five-card-para'>Craft captivating digital experiences with our UI/UX design expertise. We prioritize user-centric design principles to create intuitive interfaces that delight and engage audiences. From wireframing to prototyping, we ensure seamless navigation and visual appeal across all platforms. Elevate your brand with our innovative UI/UX design solutions tailored to your unique needs.</p>
            <button className='dgv-sec-five-btn'  onClick={()=>navigate('/web-designing')}>
              Learn More
            </button>
          </div>
          <div className='five-blog-cards'>
            <p className='dgv-sec-five-card-head'>
            Youtube Marketing 
            </p>
            <p className='dgv-sec-five-card-para'> Leverage the immense potential of YouTube with our tailored marketing strategies. We specialize in optimizing your presence on the world's second-largest search engine to drive engagement and conversions. As one of the leading digital marketing companies in Visakhapatnam, we help you harness the power of video content to grow your brand and reach your audience effectively. </p>
            <button className='dgv-sec-five-btn'  onClick={()=>navigate('/smm-services')}>
              Learn More
            </button>
          </div>
          <div className='five-blog-cards'>
            <p className='dgv-sec-five-card-head'>
            Whatsapp Marketing 
            </p>
            <p className='dgv-sec-five-card-para'> Explore the dynamic realm of WhatsApp Marketing with our innovative strategies. We specialize in leveraging this powerful platform to connect with your audience in real-time, driving engagement and conversions. From personalized messaging to automation, we help businesses harness the full potential of WhatsApp to boost brand awareness and foster meaningful interactions.</p>
            <button className='dgv-sec-five-btn'  onClick={()=>navigate('/smm-services')}>
              Learn More
            </button>
          </div>
        </div>
      </div>
      <div className='dgv-sec-seven'>
        <img src={proceess} alt='process'/>
      </div>
      <div className='dgv-sec-six'>
      <p className='dgv-sec-head-one'>
      Transform Your Business Exponentially with <strong>Top Digital Marketing in Vizag</strong>
        </p>
        <p>
        Unlock exponential business growth with top-tier digital marketing services tailored for you.
        </p>
        <div className='dgv-sec-five-blog-cards'>
          <div className='six-blog-cards'>
            <img src={seo} alt='seo'/>
            <p className='dgv-sec-five-card-head'>
            Google Ads 
            </p>
            <p className='dgv-sec-five-card-para'> 
            Harness the power of Google Ads to propel your business forward. Our expertly crafted PPC campaigns target specific audiences, driving quality traffic and maximizing ROI. With precise keyword targeting and data-driven optimization, we ensure your ads stand out in the competitive digital landscape, delivering measurable results and tangible business growth

              
            </p>
            
          </div>
          <div className='six-blog-cards'>
            <img src={facebook} alt='seo'/>
            <p className='dgv-sec-five-card-head'>
            Facebook Ads
            </p>
            <p className='dgv-sec-five-card-para'> 
            Elevate your brand's visibility and engagement with targeted Facebook Ads. Our strategic approach ensures your ads reach the right audience, driving conversions and maximizing ROI. From dynamic product ads to lead generation campaigns, we leverage Facebook's extensive user data to deliver impactful results and boost your business's online presence.

              
            </p>
            
          </div>
          <div className='six-blog-cards'>
            <img src={funnel} alt='seo'/>
            <p className='dgv-sec-five-card-head'>
            Sales Funnel
            </p>
            <p className='dgv-sec-five-card-para'> 
            Craft a seamless journey for your customers with our strategic sales funnel solutions. From awareness to conversion, we optimize every stage to drive engagement and maximize conversions. Our data-driven approach ensures a personalized experience, guiding leads through the funnel and turning them into loyal customers.

              
            </p>
            
          </div>
          <div className='six-blog-cards'>
          
          <img src={seoo} alt='seo'/>
            <p className='dgv-sec-five-card-head'>
            Seo
            </p>
            <p className='dgv-sec-five-card-para'> 
            Boost your online visibility with our expert SEO services. We specialize in optimizing your website to rank higher on search engines, driving organic traffic, and increasing your online presence. As one of the leading digital marketing companies in Vizag, we ensure your business stands out in the competitive online landscape.

              
            </p>
            
          </div>
          <div className='six-blog-cards'>
          
          <img src={blog} alt='seo'/>
            <p className='dgv-sec-five-card-head'>
            Copy Writing
            </p>
            <p className='dgv-sec-five-card-para'> 
            Elevate your brand's message with our exceptional copywriting services. Our skilled team crafts compelling content that resonates with your audience, driving engagement and conversions. From captivating website copy to persuasive ad campaigns, we ensure every word reflects your brand identity and effectively communicates your unique value proposition.

              
            </p>
            
          </div>
          <div className='six-blog-cards'>
          
          <img src={funn} alt='seo'/>
            <p className='dgv-sec-five-card-head'>
            Click Funnels
            </p>
            <p className='dgv-sec-five-card-para'> 
            Optimize your conversion journey with Click Funnels. Our tailored solutions streamline the process from lead generation to sales, maximizing ROI and minimizing friction. With customizable landing pages and automated email sequences, we guide prospects seamlessly through the funnel, turning clicks into conversions.

              
            </p>
            
          </div>

          <div className='six-blog-cards'>
          
          <img src={percentage} alt='seo'/>
            <p className='dgv-sec-five-card-head'>
            Conservation rate Optimization
            </p>
            <p className='dgv-sec-five-card-para'> 
            Maximize your online success with our Conversion Rate Optimization (CRO) services. As one of the top digital marketing companies in Visakhapatnam, we meticulously analyze user behavior and implement data-driven strategies to boost conversions. From A/B testing to personalized user experiences, we optimize every aspect of your website to drive measurable results.

              
            </p>
            
          </div>
          
          <div className='six-blog-cards'>
          <img src={marketing} alt='seo'/>
            <p className='dgv-sec-five-card-head'>
            Marketing  Automation
            </p>
            <p className='dgv-sec-five-card-para'> 
            Streamline your marketing efforts and boost efficiency with our cutting-edge marketing automation solutions. From email campaigns to social media scheduling, we automate repetitive tasks, allowing you to focus on strategy and innovation. Our tailored automation workflows ensure timely and personalized interactions with your audience, driving engagement and maximizing ROI.

              
            </p>
            
          </div>
          {/* <div className='six-blog-cards'>
          <img src={ads} alt='seo'/>
            <p className='dgv-sec-five-card-head'>
            Ecommerce Ads
            </p>
            <p className='dgv-sec-five-card-para'> 
            Supercharge your e-commerce sales with targeted e-commerce ads. Our expert team crafts compelling ad campaigns that drive traffic and conversions. From dynamic product ads to retargeting strategies, we ensure your products reach the right audience at the right time. Elevate your ecommerce presence and maximize ROI with our tailored ad solutions.

              
            </p>
            
          </div> */}
          <div className='six-blog-cards'>
          <img src={lead} alt='seo'/>
            <p className='dgv-sec-five-card-head'>
            Lead generation 
            </p>
            <p className='dgv-sec-five-card-para'> 
            Unlock your business's growth potential with our lead generation expertise. We employ strategic tactics across multiple channels to attract and capture qualified leads. From compelling content offers to targeted ad campaigns, we drive engagement and conversions, fueling your sales pipeline and ensuring sustainable business growth.

              
            </p>
            
          </div>
          {/* <div className='six-blog-cards'>
          <img src={payper} alt='seo'/>
            <p className='dgv-sec-five-card-head'>
            amazon ppc
            </p>
            <p className='dgv-sec-five-card-para'> 
            Drive sales and maximize visibility on Amazon with our expert PPC services. Our tailored campaigns target high-converting keywords and optimize product listings to increase traffic and sales. With data-driven strategies and continuous optimization, we ensure your products stand out in the competitive Amazon marketplace, delivering measurable results and driving business growth.

              
            </p>
            
          </div> */}
          <div className='six-blog-cards'>
          <img src={searchlocation} alt='seo'/>
            <p className='dgv-sec-five-card-head'>
            local seo
            </p>
            <p className='dgv-sec-five-card-para'> 
            Enhance your local visibility and attract more customers with our specialized Local SEO services. As a leading provider of digital marketing in Vizag, we optimize your online presence to dominate local search results. From Google My Business optimization to local keyword targeting, we ensure your business shines in your community, driving foot traffic and boosting sales

              
            </p>
            
          </div>
          
         <div className='six-blog-cards'>
          <img src={analysis} alt='seo'/>
             <p className='dgv-sec-five-card-head'>
            web analytics
            </p>
            <p className='dgv-sec-five-card-para'> 
            Unlock valuable insights and optimize your digital strategy with our comprehensive web analytics services. We track and analyze user behavior, traffic patterns, and conversion data to provide actionable recommendations for maximizing online performance. From understanding audience demographics to refining marketing tactics, we help you make data-driven decisions.

            </p>
            
          </div>
          <div className='six-blog-cards'>
          <img src={report} alt='seo'/>
             <p className='dgv-sec-five-card-head'>
             analysis  & reporting
            </p>
            <p className='dgv-sec-five-card-para'> 
            Gain valuable insights and track your digital marketing efforts with our thorough analysis and reporting services. We meticulously analyze campaign performance, user engagement, and conversion metrics to provide actionable recommendations for improvement. Our comprehensive reports ensure transparency and accountability, empowering you to make informed decisions and achieve your business goals effectively.

            </p>
            
          </div>
      </div>
      </div>
      <div className="smm-sixth-sec">
        <p className="right-smm-heading second-sec-right">
        Why Vasmi Group Stands Out Among <strong>Digital Marketing Companies in Vizag</strong>?
        </p>
        <div className="smm-sixth-crds">

        <div className="smm-sixth-crd">
          <img src={capitan} alt="cool"/>
          <p className="smm-sisth-crd-para">
          Unlock the potential of your online presence with top-tier agencies specializing in strategic digital marketing solutions tailored to your unique business objectives by the leading Digital Marketing Companies in Vizag.

          </p>
        </div>
        <div className="smm-sixth-crd">
          <img src={capitan} alt="cool"/>
          <p className="smm-sisth-crd-para">
          Elevate Your Brand with Expertise: Partner with Visakhapatnam's premier digital marketing firms renowned for their proficiency in leveraging cutting-edge strategies, driving targeted traffic, and maximizing ROI for businesses across diverse industries.

          </p>
        </div>
        <div className="smm-sixth-crd">
          <img src={capitan} alt="cool"/>
          <p className="smm-sisth-crd-para">
          Gain a competitive edge with digital marketing companies in Vizag that utilize advanced analytics to identify consumer behavior patterns, optimize campaigns, and deliver measurable results that matter.

          </p>
        </div>
        <div className="smm-sixth-crd">
          <img src={capitan} alt="cool"/>
          <p className="smm-sisth-crd-para">
          Whether you're a startup or a large enterprise, find digital marketing agencies in Visakhapatnam equipped to craft bespoke strategies aligned with your brand identity, goals, and budgetary requirements.

          </p>
        </div>
        <div className="smm-sixth-crd">
          <img src={capitan} alt="cool"/>
          <p className="smm-sisth-crd-para">
          With the guidance of Vizag's digital marketing experts, keep pace with evolving trends and technologies, ensuring your brand maintains relevance and visibility in an ever-changing online ecosystem.

          </p>
        </div>
        <div className="smm-sixth-crd">
          <img src={capitan} alt="cool"/>
          <p className="smm-sisth-crd-para">
          Partnering with reputable digital marketing companies in vizag empowers your business to enhance brand awareness, effectively engage your target audience, and achieve sustainable growth in today's competitive marketplace.

          </p>
        </div>
        <div className="smm-sixth-crd">
          <img src={capitan} alt="cool"/>
          <p className="smm-sisth-crd-para">
          Explore Vizag's vibrant digital landscape with our expert digital marketing solutions. Elevate your online presence and engage local audiences effectively with tailored strategies from top digital marketing companies in Vizag.

          </p>
        </div>
        <div className="smm-sixth-crd">
          <img src={capitan} alt="cool"/>
          <p className="smm-sisth-crd-para">
          Unleash the power of Visakhapatnam's digital realm with our comprehensive digital marketing services. Drive growth, amplify brand visibility, and stay ahead of the competition with proven strategies crafted by us.

          </p>
        </div>
        <div className="smm-sixth-crd">
          <img src={capitan} alt="cool"/>
          <p className="smm-sisth-crd-para">
          Unleash the power of Visakhapatnam's digital realm with our comprehensive digital marketing services. Drive growth, amplify brand visibility, and stay ahead of the competition with proven strategies crafted by us.

          </p>
        </div>
        </div>

      </div>
      <div className='dmv-sec-three'>
      <p className='dmg-sec-two-para'>
      Our Clients

      </p>
      <div className="clients">
        <div className="cli">
          <img src={agv} alt="agv" />
          <img src={lando} alt="agv" />
          <img src={techlash} alt="agv" />
          <img src={js} alt="agv" />
          <img src={ex} alt="agv" />
          <img src={agv} alt="agv" />
          <img src={techlash} alt="agv" />
          <img src={js} alt="agv" />
          <img src={ex} alt="agv" />
        </div>
      </div>


      </div>
      <p className='about-sec-two-head'>
        Frequently Asked Questions
      </p>
      <div className="accord">
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{textAlign:'left', width: "100%", flexShrink: 0, color: "orange" }}>
            What is digital marketing and why is it important for my business?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            Digital marketing encompasses various online strategies and channels aimed at promoting your business, products, or services. It's crucial for your business because it allows you to reach a wider audience, increase brand visibility, and drive conversions in the digital realm, where consumers spend a significant amount of their time.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{textAlign:'left', width: "100%", flexShrink: 0, color: "orange" }}>
            How can digital marketing help me reach my target audience?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            Digital marketing employs targeted strategies such as search engine optimization (SEO), social media marketing, email marketing, and pay-per-click (PPC) advertising to connect with your specific target audience. By leveraging demographic data, interests, and online behaviors, digital marketing ensures that your message reaches the right people at the right time.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography sx={{ textAlign:'left', flexShrink: 0, color: "orange" }}>
            What are the different components of digital marketing?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            Digital marketing comprises various components, including but not limited to SEO, social media marketing, content marketing, email marketing, PPC advertising, influencer marketing, and website optimization. Each component plays a unique role in driving traffic, engagement, and conversions for your business.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{ textAlign:'left', flexShrink: 0, color: "orange" }}>
            How can I measure the success of my digital marketing<br/> campaigns?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            The success of your digital marketing campaigns can be measured using key performance indicators (KPIs) such as website traffic, conversion rates, click-through rates, social media engagement, email open rates, and return on investment (ROI). By tracking these metrics, you can assess the effectiveness of your campaigns and make data-driven decisions to optimize performance.

            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel7"}
          onChange={handleChange("panel7")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{textAlign:'left', width: "100%", flexShrink: 0, color: "orange" }}>
            What sets Vasmi Group apart as a digital marketing agency?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            Vasmi Group stands out as a leading digital marketing agency due to our comprehensive approach, strategic expertise, and dedication to delivering measurable results. We tailor our strategies to align with your unique business goals and target audience, leveraging cutting-edge techniques to maximize your online presence and drive growth.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel6"}
          onChange={handleChange("panel6")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography sx={{textAlign:'left', width: "100%", flexShrink: 0, color: "orange" }}>
            What types of businesses can benefit from digital marketing services?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            Businesses of all sizes and industries can benefit from digital marketing services. Whether you're a small startup looking to establish your brand presence, a mid-sized company seeking to expand your reach, or a large corporation aiming to enhance customer engagement, digital marketing offers scalable solutions tailored to your specific needs and objectives.

            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="home-form">
        <div className="left-form-sec">
          <p className="home-left-form">SO What Next</p>
          <h1 className="lets-work-form">Let’s Work</h1>
          <p className="not-sure-form">Not Sure Where To start?</p>
          <form method="post" className="form-hom" onSubmit={handleSubmit}>
          <div className="inp-error">

      <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
      {nameError && <p className="error">{nameError}</p>}
          </div>
          <div className="inp-error">

      <input type="text" placeholder="PhoneNumber" value={phone} onChange={(e) => setPhone(e.target.value)} />
      {phoneError && <p className="error">{phoneError}</p>}
      </div>
      <div className="inp-error">

      <input type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
      {emailError && <p className="error">{emailError}</p>}
      </div>
      <div className="inp-error">

      <select name="services" placeholder="Services Interested In" id="services" onChange={handleSelect}>
        <option value="">-- Select Service --</option> {/* Default option */}
        {services.map((serviceOption) => (
          <option key={serviceOption.value} value={serviceOption.value}>
            {serviceOption.label}
          </option>
        ))}
      </select>
      {serviceError && <p className="error">{serviceError}</p>}
      </div>
      <div className="inp-error">

      <textarea name="Message" id="#" cols="30" rows="10" placeholder="Message" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
      {messageError && <p className="error">{messageError}</p>}
      </div>
      <div className="successmeet">

      <button>Submit</button>
      {messageSuccess && <p className="success">{messageSuccess}</p>}
      </div>


    </form>
        </div>
        <div className="right-form-sec">
          <h2 className="left-work-form">Let’s Talk</h2>
          <p className="call-form-home">+91-8142188880</p>
          <p className="call-form-home">+91-7095355556</p>
          <p className="mail-form-home">
            {" "}
            <img src={mail} alt="mail" /> Info@vasmigroup.com
          </p>
        </div>
      </div>

    </div>
  )
}

export default DigitalMarketing
