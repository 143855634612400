import React, { useState } from "react";
import "./sem.css";

import vector from "../../assets/Vectorc.png";
import seminar from "../../assets/seminar.png";
import ads from "../../assets/ads.svg";
import ads1 from "../../assets/ads1.svg";
import ads2 from "../../assets/ads2.svg";
import ads3 from "../../assets/ads3.svg";
import ads4 from "../../assets/ads4.svg";
import ads5 from "../../assets/costper.svg";
import ads6 from "../../assets/like.svg";
import ads7 from "../../assets/medal.svg";
import ads8 from "../../assets/turret.svg";
import ads9 from "../../assets/health.svg";
import ads0 from "../../assets/websit.svg";
import mail from "../../assets/mail.svg";
import lando from "../../assets/lando.png";
import techlash from "../../assets/techlash.png";
import ex from "../../assets/ex.png";
import js from "../../assets/js.png";
import agv from "../../assets/agv.png";
import { Accordion, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Helmet } from 'react-helmet';

export const Sem = () => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
 //let us talk form code begin

 const [name, setName] = useState('');
 const [email, setEmail] = useState('');
 const [phone, setPhone] = useState('');
 const [service, setService] = useState('');
 const [message, setMessage] = useState('');
 const [nameError, setNameError] = useState('');
 const [emailError, setEmailError] = useState('');
 const [phoneError, setPhoneError] = useState('');
 const [serviceError, setServiceError] = useState('');
 const [messageError, setMessageError] = useState('');
 const [messageSuccess, setMessageSuccess] = useState('');

 const services = [
  { value: 'branding analysis', label: 'Branding Analysis' },
  { value: 'graphic-designer', label: 'Graphic Designer' },
  { value: 'digital-marketing', label: 'Digital Marketing' },
  { value: 'new business packages', label: 'New Business Packages' },
  { value: 'web development', label: 'Web Development' },
  { value: 'app development', label: 'App Development' },
];
 const handleSelect = (event) => {
   setService(event.target.value);
 };

 const handleSubmit = async (event) => {
   event.preventDefault();

   // Clear previous errors
   setNameError('');
   setEmailError('');
   setPhoneError('');
   setServiceError('');
   setMessageError('');

   // Validation checks
   if (!name.trim()) {
     setNameError('Please enter your name.');
     return;
   }

   if (!email.trim()) {
     setEmailError('Please enter your email.');
     return;
   }

   // Email validation using regular expression
   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
   if (!emailRegex.test(email)) {
     setEmailError('Please enter a valid email address.');
     return;
   }

   if (!phone.trim()) {
     setPhoneError('Please enter your phone number.');
     return;
   }

   // Phone number validation using regular expression
   const phoneRegex = /^[0-9]{10}$/;
   if (!phoneRegex.test(phone)) {
     setPhoneError('Please enter a valid 10-digit phone number.');
     return;
   }

   if (!service) {
     setServiceError('Please select a service.');
     return;
   }

   if (!message.trim()) {
     setMessageError('Please enter your message.');
     return;
   }

   // If all validations pass, proceed with form submission
   const data = { name, email, phone, service, message };
   setMessageSuccess("We will get back to you")
   console.log('Form data:', data);
    try {
      const response = await fetch('https://www.vasmigroup.com/api/seo-form.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
        mode: 'no-cors'
      });

      const result = await response.json();
      console.log('Form submission result:', result);
    } catch (error) {
      console.error('Error submitting form:', error.message);
    }

   // Continue with form submission or API call
   setName('');
   setEmail('');
   setPhone('');
   setService('');
   setMessage('');
  }

  return (
    <div className="sem-main">
       <Helmet>
        <title>SEM Services company in vizag | Vasmi Group</title>
        <meta name="description" content="Vasmi Group offers SEM services to drive targeted traffic, maximize ROI, and effectively reach your audience for business goals." />
      </Helmet>
      <div className="sem-main-one">
        <div className="social-media-sem">
          <h1 className="social-media-agency">
            Top Social Media Agency In Vizag
          </h1>
          <p className="our-company-sem">
            Our Campain s craft is based on the user’s customer journey and
            buying intent. our Ongoing PPC Services include but are not limited
          </p>

          <div className="social-vector">
            <p className="media-sem">
              <img src={vector} alt="vector" />
              Google Ads
            </p>
            <p className="media-sem">
              <img src={vector} alt="vector" />
              Bing Ads
            </p>
            <p className="media-sem">
              <img src={vector} alt="vector" />
              Amazon Ads
            </p>
            <p className="media-sem">
              <img src={vector} alt="vector" />
               Shopping Ads
            </p>
            <p className="media-sem">
              <img src={vector} alt="vector" />
              Youtube Ads
            </p>
            <p className="media-sem">
              <img src={vector} alt="vector" />
              Facebook Ads
            </p>
            <p className="media-sem">
              <img src={vector} alt="vector" />
              Instagram Ads
            </p>
            <p className="media-sem">
              <img src={vector} alt="vector" />
              Linkedin Ads
            </p>
            <p className="media-sem">
              <img src={vector} alt="vector" />
              Twitter Ads
            </p>
          </div>
        </div>
        <img src={seminar} alt="seminar" className="semi" />
      </div>
      <div className="sem-main-two">
        <h2 className="sem-main-two-para">
          Customised PPC Services to Match Your Business Marketing Objects
        </h2>
        <p className="our-company-sem sem-two">
          Our Campains craft is based on the user’s customer journey and buying
          intent. our Ongoing PPC Services include but are not limited
        </p>

        <div className="google-ads-sem">
          <div className="google-ads-one-sem">
            <img src={ads} alt="ads" />
            <p className="google-ads-card">Google Shopping Ads</p>
            <p className="google-ads-card-para">
            Google Shopping Ads display product images, prices, and store information to users searching for specific products, enabling e-commerce businesses to showcase their offerings prominently and drive qualified traffic to their online stores.
            </p>
          </div>
          <div className="google-ads-one-sem">
            <img src={ads1} alt="ads" />
            <p className="google-ads-card">Google Searching Ads</p>
            <p className="google-ads-card-para">
            Google Search Ads appear at the top of search engine results pages (SERPs), targeting users actively searching for relevant keywords, driving website traffic, and increasing visibility for businesses looking to capture high-intent leads.
            </p>
          </div>
          <div className="google-ads-one-sem">
            <img src={ads2} alt="ads" />
            <p className="google-ads-card">Google Remarking Ads</p>
            <p className="google-ads-card-para">
            Google Remarketing Ads target users who have previously visited a website or interacted with its content, displaying tailored ads across the Google Display Network, reminding them of products or services they showed interest in, and completing their purchase.
            </p>
          </div>
          <div className="google-ads-one-sem">
            <img src={ads3} alt="ads" />
            <p className="google-ads-card">Google Call-only Ads</p>
            <p className="google-ads-card-para">
            Google Call-Only Ads allow businesses to promote their phone number directly on search results, encouraging users to call them directly from the ad, making it an effective strategy for service-oriented businesses aiming to generate leads and conversions via phone calls.
            </p>
          </div>
          <div className="google-ads-one-sem">
            <img src={ads4} alt="ads" />
            <p className="google-ads-card">Youtube Ads</p>
            <p className="google-ads-card-para">
            YouTube Ads enable businesses to reach a vast audience through video content, targeting users based on demographics, interests, and viewing behavior, increasing brand awareness, engagement, and driving traffic to websites or landing pages.
            </p>
          </div>
          <div className="google-ads-one-sem">
            <img src={ads5} alt="ads" />
            <p className="google-ads-card">Google Express</p>
            <p className="google-ads-card-para">
            Google Express facilitates shopping experiences by showcasing products from various retailers in one platform, allowing users to browse and purchase items and sales opportunities in the competitive online marketplace.
            </p>
          </div>
          <div className="google-ads-one-sem">
            <img src={ads6} alt="ads" />
            <p className="google-ads-card">Local PPC ads</p>
            <p className="google-ads-card-para">
            Local PPC Ads target users in specific geographic locations, promoting products or services to local audiences searching for related keywords, driving foot traffic to brick-and-mortar stores, and increasing visibility within the local community.
            </p>
          </div>
          <div className="google-ads-one-sem">
            <img src={ads7} alt="ads" />
            <p className="google-ads-card">Google display Ads</p>
            <p className="google-ads-card-para">
            Google Display Ads appear across websites within the Google Display Network, featuring text, image, or video content tailored to users' interests and browsing behaviour, enhancing brand visibility, and driving website traffic or conversions.
            </p>
          </div>
          <div className="google-ads-one-sem">
            <img src={ads7} alt="ads" />
            <p className="google-ads-card">performance max Ads</p>
            <p className="google-ads-card-para">Performance Max Ads leverage machine learning and automation to optimize campaigns across multiple Google properties, including Search, Display, and YouTube, maximizing reach and conversions while simplifying campaign management for businesses.            </p>
          </div>
        </div>
        <div className="experts-worked-sem">
          <p className="experts-heading-sem">
            Our Google Ads{" "}
            <strong className="experts-strong">
              Experts Worked For the Following Industries
            </strong>
          </p>
          <p className="campaign-sem">
            Our Campain s craft is based on the user’s customer journey and
            buying intent. our Ongoing PPC Services include but are not limited
            in the fallowing
          </p>
          <div className="google-ads-sem">
            <div className="google-ads-one-sem">
              <img src={ads8} alt="ads" />
              <p className="google-ads-card">Ecommerce Brands</p>
              <p className="google-ads-card-para">
              Our Google experts specialize in maximizing online visibility and driving sales for e-commerce brands through targeted advertising strategies, dynamic product campaigns, and optimized shopping experiences tailored to meet specific business goals.
              </p>
            </div>
            <div className="google-ads-one-sem">
              <img src={ads9} alt="ads" />
              <p className="google-ads-card">Health  & Wellness</p>
              <p className="google-ads-card-para">
              Leveraging Google's powerful advertising platforms, our experts help health and wellness businesses reach their target audience effectively, promoting services, products, and informative content to drive engagement, appointments, and conversions.
              </p>
            </div>
            <div className="google-ads-one-sem">
              <img src={ads0} alt="ads" />
              <p className="google-ads-card">Home Improvement</p>
              <p className="google-ads-card-para">With expertise in Google advertising, home improvement businesses to showcase their services and products to homeowners searching for renovation solutions, inquiries, and project bookings through targeted campaigns and compelling ad creatives.              </p>
            </div>
            <div className="google-ads-one-sem">
              <img src={ads8} alt="ads" />
              <p className="google-ads-card">Professional Services</p>
              <p className="google-ads-card-para">
              Our Google experts craft tailored advertising strategies for professional service providers, such as law firms, consulting agencies, and financial advisors, leveraging Google Ads to increase visibility, attract qualified leads, and grow client bases effectively.
              </p>
            </div>
            <div className="google-ads-one-sem">
              <img src={ads9} alt="ads" />
              <p className="google-ads-card">Real Estate</p>
              <p className="google-ads-card-para">
              Utilizing Google's advertising platforms, our experts assist real estate professionals in showcasing property listings, attracting prospective buyers or renters, and driving inquiries through targeted campaigns, captivating ad creatives, and strategic audience targeting.
              </p>
            </div>
            <div className="google-ads-one-sem">
              <img src={ads0} alt="ads" />
              <p className="google-ads-card">Small Business</p>
              <p className="google-ads-card-para">   Our Google experts understand the unique challenges faced by small businesses and offer tailored advertising solutions to increase brand awareness, attract local customers, and drive conversions through cost-effective and results-driven Google Ads campaigns.           </p>
            </div>
          </div>
        </div>
      </div>

      <div className='dmv-sec-three'>
      <p className='dmg-sec-two-para'>
      Our Clients

      </p>
      <div className="clients">
        <div className="cli">
          <img src={agv} alt="agv" />
          <img src={lando} alt="agv" />
          <img src={techlash} alt="agv" />
          <img src={js} alt="agv" />
          <img src={ex} alt="agv" />
          <img src={agv} alt="agv" />
          <img src={techlash} alt="agv" />
          <img src={js} alt="agv" />
          <img src={ex} alt="agv" />
        </div>
      </div>
      </div>
      <p className='about-sec-two-head'>
        Frequently Asked Questions
      </p>
      <div className="accord">
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{textAlign:'left', width: "100%", flexShrink: 0, color: "orange" }}>
            What is the difference between SEM and SMM, and which is more suitable for my business?

            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            SEM (Search Engine Marketing) involves paid advertising to increase search engine visibility, while SMM (Social Media Marketing) focuses on engaging audiences through social platforms. The choice depends on your objectives; SEM is ideal for immediate results and targeting specific keywords, while SMM is great for building brand awareness and fostering community engagement.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{textAlign:'left', width: "100%", flexShrink: 0, color: "orange" }}>
            Which platforms can I use for SEM and SMM campaigns?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            For SEM campaigns, platforms like Google Ads and Bing Ads are popular choices, offering keyword-based advertising on search engine results pages. SMM campaigns can be executed on social media platforms such as Facebook, Instagram, Twitter, and LinkedIn, each providing unique targeting options and ad formats.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography sx={{ textAlign:'left', flexShrink: 0, color: "orange" }}>
            How do SEM and SMM impact my website's visibility <br/> and traffic?

            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            SEM drives immediate visibility by displaying ads prominently in search engine results, attracting targeted traffic to your website. SMM builds brand awareness and fosters engagement through social platforms, driving traffic and encouraging interaction with your content.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{ textAlign:'left', flexShrink: 0, color: "orange" }}>
            What factors influence the success of SEM and SMM <br/>campaigns?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            Success in SEM relies on factors like keyword selection, ad relevance, bid strategy, and landing page experience. SMM success is influenced by content quality, audience targeting, engagement metrics, and consistency in posting and interaction.

            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel7"}
          onChange={handleChange("panel7")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{textAlign:'left', width: "100%", flexShrink: 0, color: "orange" }}>
            Are SEM and SMM suitable for businesses of all sizes and budgets?

            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            Both SEM and SMM can be tailored to businesses of all sizes and budgets. SEM offers flexibility in bidding and budget control, while SMM provides options for organic engagement and paid advertising with customizable budgets to suit varying needs.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel6"}
          onChange={handleChange("panel6")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography sx={{textAlign:'left', width: "100%", flexShrink: 0, color: "orange" }}>
            How does Vasmi Group approach SEM and SMM to maximize ROI for clients?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            Vasmi Group employs a strategic approach to SEM and SMM, combining in-depth keyword research, compelling ad creative, targeted audience segmentation, and continuous performance monitoring. By optimizing campaigns for maximum ROI, we ensure that clients achieve their objectives effectively within their allocated budgets.

            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="home-form">
        <div className="left-form-sec">
          <p className="home-left-form">SO What Next</p>
          <p className="lets-work-form">Let’s Work</p>
          <p className="not-sure-form">Not Sure Where To start?</p>
          <form method="post" className="form-hom" onSubmit={handleSubmit}>
          <div className="inp-error">

      <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
      {nameError && <p className="error">{nameError}</p>}
          </div>
          <div className="inp-error">

      <input type="text" placeholder="PhoneNumber" value={phone} onChange={(e) => setPhone(e.target.value)} />
      {phoneError && <p className="error">{phoneError}</p>}
      </div>
      <div className="inp-error">

      <input type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
      {emailError && <p className="error">{emailError}</p>}
      </div>
      <div className="inp-error">

      <select name="services" placeholder="Services Interested In" id="services" onChange={handleSelect}>
        <option value="">-- Select Service --</option> {/* Default option */}
        {services.map((serviceOption) => (
          <option key={serviceOption.value} value={serviceOption.value}>
            {serviceOption.label}
          </option>
        ))}
      </select>
      {serviceError && <p className="error">{serviceError}</p>}
      </div>
      <div className="inp-error">

      <textarea name="Message" id="#" cols="30" rows="10" placeholder="Message" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
      {messageError && <p className="error">{messageError}</p>}
      </div>
      <div className="successmeet">

      <button>Submit</button>
      {messageSuccess && <p className="success">{messageSuccess}</p>}
      </div>


    </form>
        </div>
        <div className="right-form-sec">
          <p className="left-work-form">Let’s Talk</p>
          <p className="call-form-home">+91-8142188880</p>
          <p className="call-form-home">+91-7095355556</p>
          <p className="mail-form-home">
            {" "}
            <img src={mail} alt="mail" />Info@vasmigroup.com
          </p>
        </div>
      </div>
     
    </div>
  );
};
