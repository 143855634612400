import React from "react";
import "./footer.css";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import YoutubeIcon from "@mui/icons-material/YouTube";
import { Link } from "react-router-dom";
const Footer = () => {
  const NewTwitterIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
  <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
</svg>
  );
  return (
    <div className="footer-main">
      <div className="footer-first-sec">



      <div className="sec-one-footer">
      <p className="product-footer">AboutUs</p>
<p className="about-footer-para">Vasmi Group is a leading advertising agency in Vizag that specializes in services like 
SEO, SMM, SEM, Graphic Design, UI/UX design, Website designing, Mobile App design and development.
          </p>
          
        </div>
        <div className="sec-one-footer">
          <p className="product-footer">Services</p>
          <Link className="link" text="graphic" to="/digital-marketing">
          <p className="product-items">Digital Marketing</p>
          </Link>
          <Link className="link" text="graphic" to="/smm-services">
          <p className="product-items">SMM Services</p>
          </Link>
          <Link className="link" text="graphic" to="/seo-services">
          <p className="product-items">SEO Services</p>
          </Link>
          <Link className="link" text="graphic" to="/sem-services">
          <p className="product-items">SEM Services</p>
          </Link>

        </div>
      <div className="sec-one-footer">
          <p className="product-footer">Services</p>
          <Link className="link" text="graphic" to="/graphic-designing">
          <p className="product-items">Graphic Designing</p>
          </Link>
          <Link className="link" text="graphic" to="/web-designing">
          <p className="product-items">Web Designing</p>
          </Link>
          <Link className="link" text="graphic" to="/recruitment">
          <p className="product-items">Recruitment</p>
          </Link>
          <Link className="link" text="graphic" to="/blog">
          <p className="product-items">Blog Articles</p>
          </Link>

        </div>
        {/* <div className="sec-one-footer">
          <p className="product-footer">Case Studies</p>

          <p className="product-items">Web-designers</p>
          <p className="product-items">Marketers</p>
          <p className="product-items">Small Business</p>
          <p className="product-items">Website Builder</p>
        </div> */}
        
        
      </div>
      <div className="footer-line"></div>
      <div className="footer-last-sec">
        <div className="last-sec-one">
          <p className="last-item-footer">Phone : <a href="tel:8142188880">+91 81421 88880</a></p>
          <p className="last-item-footer">© 2024 Vasmi Group</p>
        </div>
        <div className="last-sec-one">
          <p className="last-item-footer">
            Address : #39-14-8/5 ,Madhavadara-Murali Nagar Jn, Beside ICICI Bank , Visakhapatnam - 530007.
          </p>
          <p className="last-item-footer">
          <a href="sitemap.xml" style={{color:"white"}}  rel="noreferrer" target="_blank">
          Sitemap 
          </a>
          </p>
        </div>
        <div className="last-sec-one">
          <p className="last-item-footer">Email : <a href="mailto:office@vasmigroup.com">office@vasmigroup.com</a></p>
          <div className="social-media-footer">
          <a href="https://youtube.com/@vasmigroup?si=U_HXgC3s9zfqbBBR" style={{color:"white"}} target="_blank" rel="noreferrer">

<YoutubeIcon />
</a>
          <a href="https://www.instagram.com/vasmigroup/" style={{color:"white"}} target="_blank" rel="noreferrer">

            <InstagramIcon />
          </a>
          <a href="https://www.facebook.com/vasmigroup/" style={{color:"white"}} target="_blank" rel="noreferrer">
            <FacebookIcon />
</a>
  <a href="https://twitter.com/VasmiGroup" style={{color:"white"}} target="_blank" rel="noreferrer">
  <NewTwitterIcon />
</a>
  <a href="http://www.linkedin.com/company/vasmigroup" style={{color:"white"}} target="_blank" rel="noreferrer">
  <LinkedInIcon />
</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
