import React, { useState } from 'react';
import "./about.css"

import about from "../../assets/about.jpg"
import aboutstory from "../../assets/aboutstory.png"
import sammy from "../../assets/sammy.png"
import bestcompany from "../../assets/bestcompany.png"
import certified from "../../assets/certified.png"
import organization from "../../assets/organization.png"
import comp from "../../assets/comp.png"
import diamond from "../../assets/diamond.svg"
import lando from "../../assets/lando.png";
import techlash from "../../assets/techlash.png";
import ex from "../../assets/ex.png";
import js from "../../assets/js.png";
import agv from "../../assets/agv.png";
import vasmilogo from "../../assets/sublogo.png";
import arrowpng from "../../assets/arrowpng.svg"
import leftarrow from "../../assets/leftarrow.svg";
import dileeptesto from "../../assets/dileeptesto.jpg";
import swathitesto from "../../assets/swathitesto.jpg";
import saitesto from "../../assets/saibabu.jpg";
import bhagavanbidda from "../../assets/bhagavanbidda.jpg";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Helmet } from 'react-helmet';

const About = () => {
  const [expanded, setExpanded] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const testimonials = [
    {
      name: 'Dileep',
      designation: 'Frontend Developer',
      image: dileeptesto,
      text: "As a front-end developer at Vasmi Group, I've had the privilege of contributing to innovative projects that have redefined digital experiences in Visakhapatnam. Vasmi Group's commitment to excellence and fostering a collaborative work environment has been instrumental in my professional growth. I'm proud to be a part of a company that prioritizes employee satisfaction and delivering exceptional results to clients."
    },
    {
      name: 'Bhagavan',
      designation: 'Senior Developer',
      image: bhagavanbidda,
      text: "In my role as a senior developer at Vasmi Group, I've witnessed firsthand the company's unwavering dedication to driving growth and innovation in Visakhapatnam's digital landscape. Vasmi Group's emphasis on fostering a culture of learning and collaboration has enabled me to expand my skill set and take on new challenges with confidence. I'm grateful to be a part of a team that values excellence and empowers its employees to thrive."
    },
    {
      name: 'Venkat Sai',
      designation: 'Ui& Ux Designer',
      image: saitesto,
      text: "As a UI/UX developer at Vasmi Group, I've had the opportunity to work on projects that push the boundaries of creativity and innovation. Vasmi Group's supportive work environment and emphasis on professional development have enabled me to grow both personally and technically. I'm proud to be a part of a company that prioritises employee well-being and encourages us to strive for excellence in everything we do."
    },
  ];
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  const handleLeftClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1));
  };

  const handleRightClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
  };
  return (
    <div className='about'>
      <Helmet>
        <title>About Us | Vasmi Group</title>
        <meta name="description" content="Vasmi Group, a top Vizag advertising agency, offers services including SEO, SMM, SEM, graphic design, UI/UX design, and web and mobile application development.
" />
      </Helmet>
    <div className='about-main'>
    <img src={about} alt='about'/>
    <p className='about-head-main'>About Us</p>
    </div>
    <div className='sde-sec-one about-sec-img'>
        <div className='sde-sec-one-left about-secure'>
            <p className='sde-sec-left-heading about-sec-head'>
            <strong>

            Our Story
            </strong>

            </p>
            <p className='sde-sec-one-para about-secc'>
            Our story at Vasmi Group began with a vision to revolutionize talent acquisition, IT solutions, 
            and marketing strategies in Visakhapatnam. Founded by Mr. Sai Krishna Voonna, our journey is fueled
             by a commitment to excellence and innovation. With a focus on digital marketing in Vizag and beyond, 
             we strive to stand out as the top choice for businesses seeking advertising agencies in Visakhapatnam. 
             Through strategic SEO services, we elevate brand visibility, while our mobile app development in Vizag 
             ensures cutting-edge technology solutions. As a leading recruitment agency in Visakhapatnam, 
             we connect top-tier talent with thriving businesses. At Vasmi Group, our motto is to be your trusted 
             partner on the journey to excellence.
            </p>

            
        </div>
        <img src={aboutstory} alt='sde' className='krishh'/>
      </div>
      <div className='about-sec-one'>
      <div className='about-sec-one-para'> 
      <p className='about-sec-one-story'>
      Our Vision
      </p>
      <p className='about-sec-one-sub-para'>
      At Vasmi Group, our vision is to be the architects of digital success, seamlessly blending innovation,  strategy, and expertise. We aspire to redefine business excellence by offering comprehensive digital  marketing, website development, and recruitment services. Our mission is to empower brands with a  dynamic online presence, crafted through cutting-edge websites and fuelled by strategic digital  marketing campaigns. We envision not just fulfilling but exceeding our client's expectations, creating  an ecosystem where businesses thrive, websites resonate, and teams flourish.
            </p>
      </div>
      <div className='about-sec-one-para'> 
      <p className='about-sec-one-story'>
        Our Mission
      </p>
      <p className='about-sec-one-sub-para'>
      At Vasmi Group, our mission is to catalyze your digital ascendancy through an integrated approach of  unparalleled digital marketing, bespoke website development, and strategic recruitment services.  We are committed to fostering your brand's online resonance, leveraging cutting-edge technologies  and innovative strategies. Our dedicated team strives to empower businesses with impactful digital  solutions, ensuring your digital footprint transcends expectations. Through adept website  development and astute recruitment, we aim to build not just brands, but sustainable ecosystems of  success.

            </p>
      </div>
      </div>
      <div className='about-sec-two'>
      <p className='about-sec-two-head'>
        Our Values
      </p>

      <div className='about-sec-sub-two'>
        <div className='about-sec-sub-two-one'>


        <p className='about-sec-two-head-two'>
        Innovation
        </p>

        <p className='about-sec-two-head-three'>
        Igniting tech excellence, crafting avant-garde marketing, and pioneering recruitment  solutions that redefine industry standards, ensuring clients stay ahead in the digital and IT landscape.

        </p>

        </div>
        <div className='about-sec-sub-two-one'>


        <p className='about-sec-two-head-two'>
        Collaboration
                </p>

        <p className='about-sec-two-head-three'>
        Fostering synergy between tech brilliance and strategic marketing, facilitating  seamless collaboration in our recruitment endeavors, building comprehensive solutions that drive  success for clients.

        </p>

        </div>
        <div className='about-sec-sub-two-one'>


        <p className='about-sec-two-head-two'>
        Integrity
                </p>

        <p className='about-sec-two-head-three'>
        Upholding unwavering ethical standards, ensuring transparent communication, and  delivering trustworthy marketing, IT services, and recruitment processes that cultivate  enduring relationships and client satisfaction.

        </p>

        </div>
        <div className='about-sec-sub-two-one'>


        <p className='about-sec-two-head-two'>
        Adaptability
                </p>

        <p className='about-sec-two-head-three'>
        Embracing the ever-evolving digital and IT landscape, we tailor agile strategies, ensuring  our clients remain adaptable and competitive in dynamic markets, driving sustainable success.

        </p>

        </div>
      </div>
      {/* <div className='about-sec-team'>
        <p className='about-sec-team-meet'>Meet our team</p>
        <p className='about-sec-meet'> a dynamic blend of digital enthusiasts, tech wizards, and recruitment experts  dedicated to driving your business forward
        
        </p>

        <div className='about-sec-tem-mem'>
          <img className='about-sec-mem-one' src={sammy} alt='mem'/>
          <img className='about-sec-mem-one' src={sammy} alt='mem'/>
          <img className='about-sec-mem-one' src={sammy} alt='mem'/>
          <img className='about-sec-mem-one' src={sammy} alt='mem'/>
          <img className='about-sec-mem-one' src={sammy} alt='mem'/>

          
        </div>
      </div> */}
      <h1 className='about-sec-two-head'>
      Our Credentials
      </h1>
      <div className='about-sec-awards'>
        <img src={bestcompany} alt='doom'/>
        <img src={comp} alt='doom'/>
        <img src={certified} alt='doom'/>
        <img src={organization} alt='doom'/>
      </div>

      </div>

      <div className='dmv-sec-three'>
      <h2 className='dmg-sec-two-para'>
      Our Clients

      </h2>
      <div className="clients">
        <div className="cli">
          <img src={agv} alt="agv" />
          <img src={lando} alt="agv" />
          <img src={techlash} alt="agv" />
          <img src={js} alt="agv" />
          <img src={ex} alt="agv" />
          <img src={agv} alt="agv" />
          <img src={techlash} alt="agv" />
          <img src={js} alt="agv" />
          <img src={ex} alt="agv" />
        </div>
      </div>
      </div>
      <div className="testmonials">
      <div className="left-testmo">
        <div className="inner-testo">
          <img src={testimonials[currentIndex].image} alt={testimonials[currentIndex].name} />
        </div>
      </div>
      <div className="right-testo">
        <div className="top-sec-right">
          <p className="testmon">Testimonials</p>
          <img src={vasmilogo} alt="vasmi" />
        </div>
        <p className="they-say">What They Say</p>
        <p className="quotes-testo">“</p>
        <p className="right-testo-para">
          {testimonials[currentIndex].text}
        </p>
        <p className="swathi-testo">{testimonials[currentIndex].name}</p>
        <p className="content-testo">{testimonials[currentIndex].designation}</p>
        <div className="testo-btns">
          <button className="left-btn-testo" onClick={handleLeftClick}>
           <img src={leftarrow} alt="testo"/>
          </button>
          <button className="left-btn-testo" onClick={handleRightClick}>
          <img src={arrowpng} alt="testo"/>

          </button>
        </div>
      </div>
    </div>
    {/* <p className='about-sec-two-head'>
        Frequently Asked Questions
      </p> */}
    {/* <div className="accord">
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{textAlign:'left', width: "100%", flexShrink: 0, color: "orange" }}>
            What services do you provide as a web and mobile
app development company?

            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            We offer comprehensive web and mobile app
development services, including design, development,
testing, and maintenance for a wide range of platforms
and technologies.

            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{textAlign:'left', width: "100%", flexShrink: 0, color: "orange" }}>
            How can your digital marketing agency help my
business?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            Our digital marketing agency specializes in
creating and executing strategies to enhance your online
presence, drive targeted traffic to your website or app, and
ultimately increase conversions and ROI through various
digital channels.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography sx={{ textAlign:'left',width: "100%", flexShrink: 0, color: "orange" }}>
            Are your developers experienced in the latest
technologies?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            Yes, our team of developers stays up-to-date with
the latest technologies and trends in the industry.They
have expertise in various programming languages,
frameworks, and platforms to deliver cutting-edge
solutions.

            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{ textAlign:'left',width: "100%", flexShrink: 0, color: "orange" }}>
            Can you handle both small-scale and large-scale
projects?

            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            Absolutely! Whether it's a small-scale website or
a complex enterprise-level application, we have the
capabilities to handle projects of all sizes.Our team is
experienced in scaling projects to meet specific client
needs.

            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel7"}
          onChange={handleChange("panel7")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{textAlign:'left', width: "100%", flexShrink: 0, color: "orange" }}>
            How do you ensure the security of web and mobile
applications?

            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            Security is a top priority for us. We follow
industry-standard security practices and conduct rigorous
testing throughout the development process  to identify and
address potential vulnerabilities, ensuring your
applications are secure.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel6"}
          onChange={handleChange("panel6")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography sx={{textAlign:'left', width: "100%", flexShrink: 0, color: "orange" }}>
            Do you offer ongoing support and maintenance after
the development is complete?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            Yes, we provide reliable support and maintenance
services to ensure your applications continue to perform
optimally. From bug fixes to feature enhancements, we
offer flexible support plans to meet your requirements.

            </Typography>
          </AccordionDetails>
        </Accordion>
      </div> */}
      
    </div>
  );
}

export default About;
