import React, { useState } from "react";
import "./recruiting.css";
import job from "../../assets/job.png";
import recheroimg from "../../assets/recheroimg.png";
import gallery1 from "../../assets/gallery1.png";
import gallery2 from "../../assets/gallery2.png";
import gallery3 from "../../assets/gallery3.png";
import gallery4 from "../../assets/gallery4.png";
import gallery5 from "../../assets/gallery5.png";
import gallery6 from "../../assets/gallery6.png";
import press from "../../assets/pressbutton.svg";

import safe from "../../assets/safe.svg";
import fee from "../../assets/fee.svg";
import support from "../../assets/support.svg";
import delivery from "../../assets/delivery.svg";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';


export const Recruiting = () => {
  const navigate = useNavigate()

  const [email, setEmail] = useState("");
  const [successsubscriber, setsuccesssubscriber] = useState("");

  const sendSubscriber = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    const data = { email };
    console.log(data);
    try {
      const response = await fetch('https://www.vasmigroup.com/api/subscriber.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
        mode: 'no-cors'
      });
  
      if (!response.ok) {
        throw new Error('Failed to submit form'); // Handle non-2xx response codes
      }
    } catch (error) {
      console.error('Error submitting form:', error.message);
    }
    setEmail("");
    setsuccesssubscriber("Thank you for subscribing!");

  };
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [emailr, setEmailr] = useState("");
  const [phone_number, setPhone_number] = useState("");
  const [service, setService] = useState("");
  const [nationality, setNationality] = useState("");
  const [years_of_experience, setYears_of_experience] = useState("");
  const [resume, setResume] = useState(null);
  const [handmade_con, setIsChecked] = useState(false); // Initially unchecked
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState("");

  const services = [
    { value: "Frontend Developer", label: "Frontend Developer" },
    { value: "Full Stack Developer", label: "Full Stack Developer" },
  ];

  const handleChange = (event) => {
    setService(event.target.value);
  };
  const selectFile = (event) => {
    const file = event.target.files[0];
    if (file) {
      //alert(`Selected file: ${file.name}`);
      setResume(file);
    }
  };
  const resumeSubmit = async (event) => {
    event.preventDefault();
  setErrors({});

    // Validation checks
    const errors = {};

    if (!first_name.trim()) {
      errors.firstName = 'Please enter your first name.';
    }

    if (!last_name.trim()) {
      errors.lastName = 'Please enter your last name.';
    }

    if (!emailr.trim()) {
      errors.email = 'Please enter your email.';
    } else if (!isValidEmail(emailr)) {
      errors.email = 'Please enter a valid email address.';
    }

    if (!phone_number.trim()) {
      errors.phoneNumber = 'Please enter your phone number.';
    }

    if (!nationality.trim()) {
      errors.nationality = 'Please enter your nationality.';
    }

    if (!service) {
      errors.service = 'Please select a primary role.';
    }

    if (!years_of_experience.trim()) {
      errors.yearsOfExperience = 'Please enter your years of experience.';
    } else if (isNaN(years_of_experience) || years_of_experience < 0) {
      errors.yearsOfExperience = 'Please enter a valid number of years of experience.';
    }

    if (!resume) {
      errors.resume = 'Please upload your CV.';
    }

    if (!handmade_con) {
      errors.handmadeCon = 'You must consent to be contacted by Handmade.';
    }

    // If there are validation errors, set the errors state and prevent form submission
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    const resumeup=resume;
    const data = {
      first_name,
      last_name,
      emailr,
      phone_number,
      service,
      nationality,
      years_of_experience,
      handmade_con,
      resumeup,
    };
    setSuccess("We Will get back to you")
    console.log(data);
    const formData = new FormData();
    formData.append('first_name', first_name);
    formData.append('last_name', last_name);
    formData.append('emailr',emailr);
    formData.append('phone_number', phone_number);
    formData.append('service', service);
    formData.append('nationality', nationality);
    formData.append('years_of_experience', years_of_experience);
    formData.append('resumeup', resumeup);
    formData.append('handmade_con', handmade_con);
    
    

    try {
      const response = await fetch('https://www.vasmigroup.com/api/resume-upload.php', {
        method: 'POST',
        body: formData,
      });
      
      if (response.status === 200) {
        const apiData = await response.json();
        console.log('API response data:', apiData);
        // Use the apiData in your React component
      } else {
        console.error('API request failed with status:', response.status);
      }
    } catch (error) {
      console.error('Error submitting form:', error.message);
    }
setFirst_name('');
setLast_name('');
setEmailr('');
setPhone_number('');
setService('');
setNationality('');
setYears_of_experience('');
setResume('');
setIsChecked(false); // Initially unchecked

  };
  const isValidEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  return (
    <div className="recruiting">
      <Helmet>
        <title>IT Recruitment company in vizag | Vasmi Group</title>
        <meta name="description" content="Join Vasmi Group's dynamic team and explore career opportunities, showcasing your skills in innovative projects and a vibrant workplace." />
      </Helmet>
      <div className="recruit-land">
        <div className="landing-recruit">
          <img src={recheroimg} alt="recheroimg" className="heroimg" />
          <p className="recruit-main-heading">
            Unlock Your Potential with VasmiGroup Exciting Career Opportunities
          </p>
          <p className="recruit-stand-out">
            Stand out with an impressive resume, connect with top companies, and
            gain valuable insights for your professional journey
          </p>
          <div className="recruit-btns">
            <button className="recruit-for-employess" onClick={()=>navigate('/contact-us')}>For Employees</button>
            <button className="recruit-for-candidates" onClick={()=>navigate('/contact-us')}>For Candidates</button>
          </div>
        </div>
      </div>
      <div className="job-sec-recruit">
        <img src={job} alt="job" className="job-im" />
        <div className="job-right-recruit">
          <p className="recruit-main-heading">
            We connect young professionals to internships and early career
            opportunities.
          </p>
          <p className="recruit-stand-out">
            We understand that starting a career can be daunting, which is why
            we strive to simplify the process and ensure a smooth transition
            into the professional world.
          </p>

          <button className="press-button" >
          <a href="#recruit-formm">
            {" "}
            <img src={press} alt="press" /> Apply Now
            </a>
          </button>
        </div>
      </div>
      <div className="platform-recruit">
        <p className="platform-heading-r">A platform you can trust</p>

        <div className="platform-cards-r">
          <div className="r-card-one">
            <p className="card-one-heading">Personalized Guidance</p>
            <p className="card-one-paragraph">
            Our dedicated team of manpower consultants in Vizag
            ensures personalized attention to every candidate, 
            guiding them towards fulfilling career opportunities.
            </p>
          </div>
          <div className="r-card-one">
            <p className="card-one-heading">Industry Connections</p>
            <p className="card-one-paragraph">
            With years of experience, our manpower consultants in Vizag 
            have built strong industry connections, facilitating seamless
            job placements for our candidates.
            </p>
          </div>
          <div className="r-card-one">
            <p className="card-one-heading">Transparency Priority</p>
            <p className="card-one-paragraph">
            We prioritize transparency and integrity, ensuring 
            a trustworthy experience for both employers and job seekers.
            </p>
          </div>
          <div className="r-card-one">
            <p className="card-one-heading">Comprehensive Support</p>
            <p className="card-one-paragraph">
            Our platform provides comprehensive support,
            from resume building to interview preparation,
            ensuring candidates are well-equipped for success
            </p>
          </div>
          <div className="r-card-one">
            <p className="card-one-heading">Talent Assurance</p>
            <p className="card-one-paragraph">
            Employers trust us to deliver top-tier talent thanks to our
            rigorous screening processes and commitment to quality.
            </p>
          </div>
          <div className="r-card-one">
            <p className="card-one-heading">Trusted Expertise</p>
            <p className="card-one-paragraph">
            Join our platform today and experience the 
            difference with the most trusted recruiters in Vizag.
            </p>
          </div>
        </div>
      </div>
      <div className="why-choose">
        <p className="why-choose-head">
          Why Choose <strong className="care">Vasmi Group</strong>?
        </p>
        <div className="choose-section">
          <div className="choose-one">
            <div className="imag">
              <img src={safe} alt="safe" />
            </div>
            <div className="choose-right-sec">
              <p className="right-sec-head">Expertise</p>
              <p className="right-sec-sub">
              Benefit from our experience as the best job consultancy 
              in Vizag, guiding you towards rewarding career opportunities.
              </p>
            </div>
          </div>
          <div className="choose-one ">
            <div className="imag im-col">
              <img src={delivery} alt="safe" />
            </div>
            <div className="choose-right-sec">
              <p className="right-sec-head">Proven Track Record</p>
              <p className="right-sec-sub">
              Trust Vasmi Group's consistent success in 
              placing candidates in top companies, solidifying 
              our reputation as trusted recruiters in Vizag.
              </p>
            </div>
          </div>
          <div className="choose-one ">
            <div className="imag im-fee">
              <img src={fee} alt="safe" />
            </div>
            <div className="choose-right-sec">
              <p className="right-sec-head">Personalized Approach</p>
              <p className="right-sec-sub">
              Enjoy tailored support from our dedicated team of 
              recruiters, ensuring a seamless job search experience.
              </p>
            </div>
          </div>
          <div className="choose-one ">
            <div className="imag im-support">
              <img src={support} alt="safe" />
            </div>
            <div className="choose-right-sec">
              <p className="right-sec-head">Industry Connections</p>
              <p className="right-sec-sub">
               Our extensive network ensures access to exclusive job openings,
               supported by our trusted manpower consultants in Vizag.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="resume-approach-recruit">
        <p className="approach-recruit-para">
          If your resume has not been updated in a while, let us update it
          first.
        </p>

        <form className="subscribe-form" onSubmit={sendSubscriber}>
          <input
            type="email"
            placeholder="Enter Your Email"
            className="resume-inp"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button type="submit" className="resume-mail">
            Submit
          </button>
        </form>
        {successsubscriber&& <p className="success">{successsubscriber}</p>}
      </div>
      {/* <div className="recruit-gallery">
        <p className="platform-heading-r">Our Company Gallery</p>

        <div className="gallery-r">
          <div className="gallery-r-one">
            <img src={gallery1} alt="gallery" />
            <img src={gallery2} alt="gallery" />
          </div>
          <img src={gallery3} alt="gallery" />
          <div className="gallery-r-one">
            <img src={gallery4} alt="gallery" />
            <img src={gallery5} alt="gallery" />
          </div>
          <img src={gallery6} alt="gallery" />
        </div>
      </div> */}

      <div className="recruit-formm" id="recruit-formm">
        <p className="submit-recruit-p">Submit your CV</p>
        <form action="" onSubmit={resumeSubmit}>
      <div className="form-inps-recruit">
      <div className="inp-error">

        <input
          type="text"
          placeholder="First Name"
          value={first_name}
          onChange={(e) => setFirst_name(e.target.value)}
        />
        {errors.firstName && <p className="error">{errors.firstName}</p>}
        </div>
        <div className="inp-error">

        <input
          type="text"
          placeholder="Last Name"
          value={last_name}
          onChange={(e) => setLast_name(e.target.value)}
        />
        {errors.lastName && <p className="error">{errors.lastName}</p>}
</div>
      <div className="inp-error">

        <input
          type="email"
          required
          placeholder="Email"
          value={emailr}
          onChange={(e) => setEmailr(e.target.value)}
        />
        {errors.email && <p className="error">{errors.email}</p>}
</div>
      <div className="inp-error">

        <input
          type="text"
          placeholder="Phone Number"
          value={phone_number}
          onChange={(e) => setPhone_number(e.target.value)}
        />
        {errors.phoneNumber && <p className="error">{errors.phoneNumber}</p>}
</div>
      <div className="inp-error">

        <input
          type="text"
          placeholder="Nationality"
          value={nationality}
          onChange={(e) => setNationality(e.target.value)}
        />
        {errors.nationality && <p className="error">{errors.nationality}</p>}
</div>
      <div className="inp-error">

        <select
          name="services"
          placeholder="Services Interested In"
          id="services"
          onChange={handleChange}
        >
          <option value="">-- Primary Role Interested in --</option>
          {services.map((serviceOption) => (
            <option key={serviceOption.value} value={serviceOption.value}>
              {serviceOption.label}
            </option>
          ))}
        </select>
        {errors.service && <p className="error">{errors.service}</p>}
</div>
      <div className="inp-error">

        <input
          type="text"
          placeholder="Years Of Experience"
          value={years_of_experience}
          onChange={(e) => setYears_of_experience(e.target.value)}
        />
        {errors.yearsOfExperience && <p className="error">{errors.yearsOfExperience}</p>}
</div>
      <div className="inp-error">

        <input
          type="file"
          name="d"
          id="down"
          placeholder="Upload Your CV"
          onChange={selectFile}
        />
        {errors.resume && <p className="error">{errors.resume}</p>}
</div>
      </div>

      <FormGroup>
        <FormControlLabel
        sx={{alignItems:"center",justifyContent:"center"}}
          control={
            <Checkbox
              checked={handmade_con}
              onChange={(event) => setIsChecked(event.target.checked)}
            />
          }
          label="I consent to Handmade contacting me via the information that I have provided."
        />
        {errors.handmadeCon && <p className="error">{errors.handmadeCon}</p>}
      </FormGroup>
      <div className="successmeet">

      <button className="contact-btn r-btn" type="submit">Submit</button>
      {success && <p className="success">{success}</p>}

      </div>
    </form>

      </div>
    </div>
  );
};
