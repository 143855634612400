import React, { useState } from 'react';
import "./graphic.css"
import landvideo from "../../assets/graphicd.mp4"
import briliance from "../../assets/briliance.png"
import latestproduct from "../../assets/crafts.png"
import aadyaweb from "../../assets/poster.png"
import next from "../../assets/next.png"
import javascript from "../../assets/javascript.svg"
import react from "../../assets/react.svg"
import css from "../../assets/css.svg"
import html from "../../assets/html.svg"
import torch from "../../assets/torch.png"
import appdev from "../../assets/thumbnail.png"
import dashboard from "../../assets/merry.png"
import wordpressdev from "../../assets/adverse.png"
import gallery2 from "../../assets/arrows.png";
import gallery3 from "../../assets/aadhyadesign.png";
import gallery4 from "../../assets/techlashdesign.png";
import gallery5 from "../../assets/copper.png";
import gallery6 from "../../assets/legacy.png";
import gallery1 from "../../assets/chart.png";
import mail from "../../assets/mail.svg";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import lando from "../../assets/lando.png";
import techlash from "../../assets/techlash.png";
import ex from "../../assets/ex.png";
import js from "../../assets/js.png";
import agv from "../../assets/agv.png";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const GraphicDesign = () => {
  const navigate = useNavigate()
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
 //let us talk form code begin

 const [name, setName] = useState('');
 const [email, setEmail] = useState('');
 const [phone, setPhone] = useState('');
 const [service, setService] = useState('');
 const [message, setMessage] = useState('');
 const [nameError, setNameError] = useState('');
 const [emailError, setEmailError] = useState('');
 const [phoneError, setPhoneError] = useState('');
 const [serviceError, setServiceError] = useState('');
 const [messageError, setMessageError] = useState('');
 const [messageSuccess, setMessageSuccess] = useState('');

 const services = [
  { value: 'branding analysis', label: 'Branding Analysis' },
  { value: 'graphic-designer', label: 'Graphic Designer' },
  { value: 'digital-marketing', label: 'Digital Marketing' },
  { value: 'new business packages', label: 'New Business Packages' },
  { value: 'web development', label: 'Web Development' },
  { value: 'app development', label: 'App Development' },
];

 const handleSelect = (event) => {
   setService(event.target.value);
 };

 const handleSubmit = async (event) => {
   event.preventDefault();

   // Clear previous errors
   setNameError('');
   setEmailError('');
   setPhoneError('');
   setServiceError('');
   setMessageError('');

   // Validation checks
   if (!name.trim()) {
     setNameError('Please enter your name.');
     return;
   }

   if (!email.trim()) {
     setEmailError('Please enter your email.');
     return;
   }

   // Email validation using regular expression
   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
   if (!emailRegex.test(email)) {
     setEmailError('Please enter a valid email address.');
     return;
   }

   if (!phone.trim()) {
     setPhoneError('Please enter your phone number.');
     return;
   }

   // Phone number validation using regular expression
   const phoneRegex = /^[0-9]{10}$/;
   if (!phoneRegex.test(phone)) {
     setPhoneError('Please enter a valid 10-digit phone number.');
     return;
   }

   if (!service) {
     setServiceError('Please select a service.');
     return;
   }

   if (!message.trim()) {
     setMessageError('Please enter your message.');
     return;
   }

   // If all validations pass, proceed with form submission
   const data = { name, email, phone, service, message };
   setMessageSuccess("We will get back to you")
   console.log('Form data:', data);

   // Continue with form submission or API call
   try {
    const response = await fetch('https://www.vasmigroup.com/api/graphicdesign-form.php', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
      mode: 'no-cors'
    });

    const result = await response.json();
    console.log('Form submission result:', result);
  } catch (error) {
    console.error('Error submitting form:', error.message);
  }

 // Continue with form submission or API call
 setName('');
 setEmail('');
 setPhone('');
 setService('');
 setMessage('');
 };
//letus talk form code end

  return (
    <div className='sde'>
      <Helmet>
        <title>Graphic Desigining company in vizag | Vasmi Group</title>
        <meta name="description" content="Vasmi Group offers creative graphic design services, ranging from logos to branding to elevate your brand identity and create captivating and inspiring designs." />
      </Helmet>

      <div className='sde-one'>
      <video className="landvideo" autoPlay loop muted>
        <source src={landvideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="waviy">
  <span style={{'--i': 1}}>G</span>
  <span style={{'--i': 2}}>r</span>
  <span style={{'--i': 3}}>a</span>
  <span style={{'--i': 4}}>p</span>
  <span style={{'--i': 5}}>h</span>
  <span style={{'--i': 6}}>i</span>
  <span style={{'--i': 7}}>c</span>
  <span style={{'--i': 8}}> </span>
  <span style={{'--i': 9}} className="d i">D</span>
  <span style={{'--i': 10}} className="d" >e</span>
  <span style={{'--i': 11}} className="d">s</span>
  <span style={{'--i': 12}} className="d">i</span>
  <span style={{'--i': 13}} className="d">g</span>
  <span style={{'--i': 14}} className="d">n</span>
  <span style={{'--i': 15}} className="d">i</span>
  <span style={{'--i': 16}} className="d">n</span>
  <span style={{'--i': 17}} className="d">g</span>
</div>



      </div>
      <div className='sde-sec-one'>
        <div className='sde-sec-one-left'>
            <p className='sde-sec-left-heading'>
            <strong>

            Visual Brilliance
            </strong>

            </p>
            <p className='sde-sec-one-para'>
            Welcome to the vibrant world of graphic design at Vasmi Group, where creativity meets innovation.  Our digital artists craft visual masterpieces that captivate and communicate. Elevate your brand with  stunning graphics that resonate, leaving a lasting impression on your audience. From striking logos to  eye-catching social media visuals, our design experts seamlessly blend aesthetics and strategy.  Explore the artistry that transforms concepts into compelling visuals, tailored to amplify your digital  presence. Let your brand speak visually, let our graphic design services tell your unique story.  Discover the power of design with Vasmi Group.

            </p>

            <button className='sde-sec-one-btn' onClick={()=>navigate('/recruitment')}>
            Get Started
 
            </button> 
        </div>
        <img src={briliance} alt='sde'/>
      </div>
            <p className='sde-sectwo-tools'>
                Our Tools
            </p>
      <div className='sde-tools'>
        <img src={javascript} alt='tools' className='sdetools-one'/>
        <img src={html} alt='tools' className='sdetools-one'/>
        <img src={css} alt='tools' className='sdetools-one'/>
        <img src={react} alt='tools' className='sdetools-one'/>
        <img src={next} alt='tools' className='sdetools-one'/>
      </div>


      <div className='sde-developments-tools'>
      <p className='sde-sectwo-tools'>Type of Designs we do</p>
      <div className='sde-sec-one'>
        <div className='sde-sec-one-left'>
            <p className='sde-sec-left-heading'>
            <strong>
            Logo Design
            </strong>

            </p>
            <p className='sde-sec-one-para'>
            Unlock the essence of your brand with our Logo design services. Our adept designers go beyond  aesthetics, delving into the core of your brand to create logos that resonate and endure. From sleek  and modern to timeless and classic, each logo is meticulously crafted to embody your brand's  identity. Elevate your visual representation and make a lasting impression with logos that tell your  unique story. Let your brand's personality shine through a symbol that speaks volumes. Trust our  expertise to design the face of your brand.

            </p>

            <button className='sde-sec-two-btn' onClick={()=>navigate('/contact-us')}>
            Contact us

            </button> 
        </div>
        <img src={latestproduct} alt='sde'/>
      </div>

      <div className='sde-sec-one'>
      <img src={aadyaweb} alt='sde'/>

        <div className='sde-sec-one-left'>
            <p className='sde-sec-left-heading'>
          Poster Design
           

            </p>
            <p className='sde-sec-one-para'>
            Elevate your promotions with our Poster design expertise. Our digital artisans blend creativity and strategy to craft compelling posters that grab attention and convey your message effectively. From event promotions to product launches, our designs are tailored to leave a lasting impact. We understand the power of visual communication, and our posters are designed to resonate with your target audience. Let our creative prowess transform your ideas into visually stunning posters that stand out in the digital landscape. Boost engagement and visibility with our dynamic poster creations.

            </p>

            <button className='sde-sec-two-btn' onClick={()=>navigate('/contact-us')}>
            Contact us

            </button> 
        </div>
      </div>

      <div className='sde-sec-one'>
        <div className='sde-sec-one-left'>
            <p className='sde-sec-left-heading'>
            Thumbnail Design
            </p>
            <p className='sde-sec-one-para'>
            Step into the realm of innovation with our 3D Design services. Our proficient designers bring ideas to life, transforming concepts into immersive visual experiences. From product prototypes to architectural renderings, our 3D designs push boundaries and captivate audiences. Whether it's for virtual spaces or marketing materials, our 3D creations add depth and realism to your projects. Elevate your brand with cutting-edge designs that leave a lasting impact. Explore the possibilities of dimensional storytelling and make your digital presence truly stand out. Immerse your audience in a world of 3D design magic with our expertise.

            </p>

            <button className='sde-sec-two-btn' onClick={()=>navigate('/contact-us')}>
            Contact us

            </button> 
        </div>
        <img src={appdev} alt='sde'/>
      </div>

      <div className='sde-sec-one'>
      <img src={dashboard} alt='sde'/>

        <div className='sde-sec-one-left'>
            <p className='sde-sec-left-heading'>
          3D Design
            </p>
            <p className='sde-sec-one-para'>
            Step into the realm of innovation with our 3D Design services. Our proficient designers bring ideas to life, transforming concepts into immersive visual experiences. From product prototypes to architectural renderings, our 3D designs push boundaries and captivate audiences. Whether it's for virtual spaces or marketing materials, our 3D creations add depth and realism to your projects. Elevate your brand with cutting-edge designs that leave a lasting impact. Explore the possibilities of dimensional storytelling and make your digital presence truly stand out. Immerse your audience in a world of 3D design magic with our expertise.

            </p>

            <button className='sde-sec-two-btn' onClick={()=>navigate('/contact-us')}>
            Contact us

            </button> 
        </div>
      </div>
      <div className='sde-sec-one'>
        <div className='sde-sec-one-left'>
            <p className='sde-sec-left-heading'>
           Marketing & Advertising
Design
            </p>
            <p className='sde-sec-one-para'>
            Elevate your marketing endeavors with our Marketing and Advertising design expertise. Our seasoned designers blend creativity and strategy to deliver visuals that leave a lasting impression. From impactful ad campaigns to strategically designed marketing collateral, we craft designs that resonate with your target audience. We understand the dynamics of effective communication and tailor our designs to enhance brand visibility and engagement. Let our creative prowess amplify your marketing efforts, ensuring your brand stands out in the digital landscape. Explore the synergy of design and marketing with our strategic design solutions.

            </p>

            <button className='sde-sec-two-btn' onClick={()=>navigate('/contact-us')}>
            Contact us

            </button> 
        </div>
        <img src={wordpressdev} alt='sde'/>
      </div>
      </div>

      <div className="recruit-gallery">
        <p className="platform-heading-r">Design Examples</p>

        <div className="gallery-r">
          <div className="gallery-r-one">
            <img src={gallery1} alt="gallery" />
            <img src={gallery2} alt="gallery" />
          </div>
          <img src={gallery3} alt="gallery" />
          <div className="gallery-r-one">
            <img src={gallery4} alt="gallery" />
            <img src={gallery5} alt="gallery" />
          </div>
          <img src={gallery6} alt="gallery" className='galleryout' />
        </div>
      </div>
      <div className='dmv-sec-three'>
      <p className='dmg-sec-two-para'>
      Our Clients

      </p>
      <div className="clients">
        <div className="cli">
          <img src={agv} alt="agv" />
          <img src={lando} alt="agv" />
          <img src={techlash} alt="agv" />
          <img src={js} alt="agv" />
          <img src={ex} alt="agv" />
          <img src={agv} alt="agv" />
          <img src={techlash} alt="agv" />
          <img src={js} alt="agv" />
          <img src={ex} alt="agv" />
        </div>
      </div>
      </div>
      <p className='about-sec-two-head'>
        Frequently Asked Questions
      </p>
      <div className="accord">
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{textAlign:'left', width: "100%", flexShrink: 0, color: "orange" }}>
            What is graphic design, and why is it important for businesses?

            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            Graphic design involves creating visual content to communicate messages effectively. It's crucial for businesses as it helps establish brand identity, convey information visually, and engage audiences across various platforms.

            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{textAlign:'left', width: "100%", flexShrink: 0, color: "orange" }}>
            Which graphic design elements are essential for creating impactful visual content?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            Essential graphic design elements include typography, color theory, layout composition, imagery selection, and branding elements like logos and icons, all working together to create visually appealing and cohesive designs.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography sx={{ textAlign:'left', flexShrink: 0, color: "orange" }}>
            How can graphic design enhance my marketing efforts?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            Graphic design plays a key role in marketing by creating eye-catching advertisements, social media graphics, website visuals, and marketing collateral that capture attention, communicate brand messaging, and drive engagement and conversions.

            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{ textAlign:'left', flexShrink: 0, color: "orange" }}>
            What software tools do graphic designers use to <br/>create designs?

            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            Graphic designers use a variety of software tools, including Adobe Creative Suite (such as Photoshop, Illustrator, and InDesign), Sketch, Canva, and Affinity Designer, each offering different features and capabilities for creating professional-quality designs.

            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel7"}
          onChange={handleChange("panel7")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{textAlign:'left', width: "100%", flexShrink: 0, color: "orange" }}>
            Is professional graphic design necessary for businesses, or can I create designs myself?


            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            While DIY design tools are available, professional graphic design services offer expertise, creativity, and attention to detail that can elevate your brand and ensure consistency and quality across all visual assets, making it a worthwhile investment for businesses.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel6"}
          onChange={handleChange("panel6")}
          sx={{ width: "500px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography sx={{textAlign:'left', width: "100%", flexShrink: 0, color: "orange" }}>
            How does Vasmi Group approach graphic design to meet clients' needs and objectives?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{textAlign:"left",color:"#f5a005de",fontWeight:300}}>
            Vasmi Group takes a client-centric approach to graphic design, understanding clients' brand identity, target audience, and objectives to create custom designs that effectively communicate their message and resonate with their audience, ensuring maximum impact and ROI.

            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>

      <div className="home-form">
        <div className="left-form-sec">
          <p className="home-left-form">SO What Next</p>
          <p className="lets-work-form">Let’s Work</p>
          <p className="not-sure-form">Not Sure Where To start?</p>
          <form method="post" className="form-hom" onSubmit={handleSubmit}>
          <div className="inp-error">

      <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
      {nameError && <p className="error">{nameError}</p>}
          </div>
          <div className="inp-error">

      <input type="text" placeholder="PhoneNumber" value={phone} onChange={(e) => setPhone(e.target.value)} />
      {phoneError && <p className="error">{phoneError}</p>}
      </div>
      <div className="inp-error">

      <input type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
      {emailError && <p className="error">{emailError}</p>}
      </div>
      <div className="inp-error">

      <select name="services" placeholder="Services Interested In" id="services" onChange={handleSelect}>
        <option value="">-- Select Service --</option> {/* Default option */}
        {services.map((serviceOption) => (
          <option key={serviceOption.value} value={serviceOption.value}>
            {serviceOption.label}
          </option>
        ))}
      </select>
      {serviceError && <p className="error">{serviceError}</p>}
      </div>
      <div className="inp-error">

      <textarea name="Message" id="#" cols="30" rows="10" placeholder="Message" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
      {messageError && <p className="error">{messageError}</p>}
      </div>
      <div className="successmeet">

      <button>Submit</button>
      {messageSuccess && <p className="success">{messageSuccess}</p>}
      </div>


    </form>
        </div>
        <div className="right-form-sec">
          <p className="left-work-form">Let’s Talk</p>
          <p className="call-form-home">+91-8142188880</p>
          <p className="call-form-home">+91-7095355556</p>
          <p className="mail-form-home">
            {" "}
            <img src={mail} alt="mail" />Info@vasmigroup.com
          </p>
        </div>
      </div>
    </div>
  );
}

export default GraphicDesign;
